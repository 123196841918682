import React from "react";
import { Box, CircularProgress } from "@mui/material";

// Component for displaying a centered fullscreen loading spinner
const LoadingScreen = ({ navbarHeight = 64 }) => {
  return (
    // Fullscreen centered container
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minHeight: `calc(100vh - ${navbarHeight}px)`,
        bgcolor: "background.default",
      }}
    >
      <CircularProgress />
    </Box>
  );
};

export default LoadingScreen;
