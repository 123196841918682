import React from "react";
import { Avatar, Badge, styled } from "@mui/material";

// Styled badge for online status
const OnlineBadge = styled(Badge)(({ theme }) => ({
  "& .MuiBadge-badge": {
    backgroundColor: "#44b700",
    color: "#44b700",
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    "&::after": {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      borderRadius: "50%",
      animation: "ripple 1.2s infinite ease-in-out",
      border: "1px solid currentColor",
      content: '""',
    },
  },
  // Ripple animation
  "@keyframes ripple": {
    "0%": {
      transform: "scale(.8)",
      opacity: 1,
    },
    "100%": {
      transform: "scale(2.4)",
      opacity: 0,
    },
  },
}));

const UserAvatar = ({ src, isOnline = false, sx = {}, ...props }) => {
  const AvatarComponent = <Avatar src={src} sx={sx} {...props} />;
  // Display online badge if user is online
  if (isOnline) {
    return (
      <OnlineBadge
        overlap="circular"
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        variant="dot"
      >
        {AvatarComponent}
      </OnlineBadge>
    );
  }

  return AvatarComponent;
};

export default UserAvatar;
