import React from "react";
import { Navigate } from "react-router-dom";
import { useAuth } from "../../contexts/AuthContext";
import LoadingScreen from "../common/LoadingScreen";

const PrivateTeacherRoute = ({ children }) => {
  const { user, loading } = useAuth();

  // Render a loading screen while the user is being authenticated
  if (loading) {
    return <LoadingScreen />;
  }

  const isTeacher = user?.user_type === "teacher";

  return isTeacher ? children : <Navigate to="/404" />;
};

export default PrivateTeacherRoute;
