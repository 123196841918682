import React from "react";
import { Box, Typography, Container } from "@mui/material";
import UserTypeChip from "../common/UserTypeChip";
import UserAvatar from "../common/UserAvatar";
import { usePresence } from "../../contexts/PresenceContext";
import getOtherParticipant from "./getOtherParticipant";

// Component displaying the chat room header with other participant's info
const ChatHeader = ({ room, userId }) => {
  const { isUserOnline } = usePresence();
  const otherParticipant = getOtherParticipant(room?.participants_info, userId);

  return (
    // Fixed position header with bottom border
    <Box
      sx={{
        p: 2,
        borderBottom: 1,
        borderColor: "divider",
        position: "fixed",
        height: "64px",
        width: "100%",
        zIndex: 1,
        backgroundColor: "background.paper",
      }}
    >
      {/* Container for avatar and user name */}
      <Container
        maxWidth="lg"
        sx={{ display: "flex", alignItems: "center", gap: 2 }}
      >
        <UserAvatar
          src={otherParticipant.profile_picture}
          isOnline={isUserOnline(otherParticipant.id)}
        />
        <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
          <Typography variant="h6">
            {otherParticipant.full_name || "Chat"}
          </Typography>
          <UserTypeChip userType={otherParticipant.user_type} />
        </Box>
      </Container>
    </Box>
  );
};

export default ChatHeader;
