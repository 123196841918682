import React from "react";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import { Box } from "@mui/material";

// Component for rendering markdown content with consistent styling
const MarkdownRenderer = ({ content }) => {
  return (
    <Box
      sx={{
        "& img": { maxWidth: "100%" },
        "& table": {
          // Table formatting
          borderCollapse: "collapse",
          width: "100%",
          mb: 2,
        },
        "& th, & td": {
          border: "1px solid",
          borderColor: "divider",
          p: 1,
        },
        "& blockquote": {
          borderLeft: 4,
          borderColor: "primary.main",
          borderLeftStyle: "solid",
          pl: 2,
          my: 2,
          ml: 0,
        },
        "& code": {
          backgroundColor: "action.hover",
          p: 0.5,
          borderRadius: 1,
        },
        "& pre": {
          backgroundColor: "action.hover",
          p: 2,
          borderRadius: 1,
          overflow: "auto",
        },
      }}
    >
      <ReactMarkdown remarkPlugins={[remarkGfm]}>{content}</ReactMarkdown>
    </Box>
  );
};

export default MarkdownRenderer;
