import React from "react";
import { Box, Typography, Button } from "@mui/material";
import { Link } from "react-router-dom";

// Component for displaying empty state messages with optional styling
const EmptyState = ({
  title,
  description,
  actionText,
  actionLink,
  actionIcon: ActionIcon,
}) => {
  return (
    // Container with centered content
    <Box sx={{ textAlign: "center", py: 6 }}>
      {/* Title and description text */}
      <Typography variant="h6" color="text.secondary" gutterBottom>
        {title}
      </Typography>
      <Typography variant="body1" color="text.secondary">
        {description}
      </Typography>
      {/* Action button with optional icon */}
      {actionText && actionLink && (
        <Button
          variant="contained"
          component={Link}
          to={actionLink}
          startIcon={ActionIcon && <ActionIcon />}
          sx={{ mt: 2 }}
        >
          {actionText}
        </Button>
      )}
    </Box>
  );
};

export default EmptyState;
