import React from "react";
import {
  Box,
  Typography,
  Paper,
  Avatar,
  Button,
  Grid,
  Divider,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import { Link, useNavigate } from "react-router-dom";
import { stringAvatar } from "../../utils/avatarUtils";
import { getImageUrl } from "../../utils/imageUtils";
import { format } from "date-fns";
import ChatIcon from "@mui/icons-material/Chat";
import UserTypeChip from "../common/UserTypeChip";

const ProfileInfo = ({ profile, isOwner }) => {
  const navigate = useNavigate();

  const handleChat = () => {
    navigate(`/chat/new?userId=${profile.id}`);
  };

  return (
    <Paper elevation={2} sx={{ p: 3, position: "sticky", top: "84px" }}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          mb: 3,
        }}
      >
        {profile?.profile_picture ? (
          <Avatar
            src={getImageUrl(profile.profile_picture)}
            sx={{ width: 120, height: 120, mb: 2 }}
          />
        ) : (
          <Avatar
            {...stringAvatar(profile?.full_name)}
            sx={{ width: 120, height: 120, mb: 2 }}
          />
        )}
        <Typography variant="h5" gutterBottom align="center">
          {profile?.full_name}
        </Typography>

        {/* User Type Chip with large variant */}
        {profile?.user_type && (
          <UserTypeChip
            userType={profile.user_type}
            large={true}
            sx={{ mb: 2 }}
          />
        )}

        {!isOwner && (
          <Button
            startIcon={<ChatIcon />}
            variant="contained"
            color="primary"
            onClick={handleChat}
            fullWidth
            sx={{ mt: 2 }}
          >
            Chat
          </Button>
        )}

        {isOwner && (
          <Button
            startIcon={<EditIcon />}
            variant="outlined"
            component={Link}
            to={`/profile/${profile.id}/edit`}
            fullWidth
            sx={{ mt: 2 }}
          >
            Edit Profile
          </Button>
        )}
      </Box>

      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography color="text.secondary" variant="subtitle2">
            Username
          </Typography>
          <Typography variant="body2" fontWeight={700}>
            @{profile?.username}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography color="text.secondary" variant="subtitle2">
            Email
          </Typography>
          <Typography variant="body2">{profile?.email}</Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography color="text.secondary" variant="subtitle2">
            Joined
          </Typography>
          <Typography variant="body2">
            {profile?.created_at
              ? format(new Date(profile.created_at), "MMMM d, yyyy")
              : ""}
          </Typography>
        </Grid>
        {profile?.bio && (
          <Grid item xs={12}>
            <Divider sx={{ my: 1 }} />
            <Typography color="text.secondary" variant="subtitle2">
              Biography
            </Typography>
            <Typography variant="body2" sx={{ whiteSpace: "pre-wrap" }}>
              {profile.bio}
            </Typography>
          </Grid>
        )}
        {profile?.phone_number && (
          <Grid item xs={12}>
            <Typography color="text.secondary" variant="subtitle2">
              Phone Number
            </Typography>
            <Typography variant="body2">{profile.phone_number}</Typography>
          </Grid>
        )}
      </Grid>
    </Paper>
  );
};

export default ProfileInfo;
