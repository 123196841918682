import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
  Navigate,
} from "react-router-dom";
import { AuthProvider } from "./contexts/AuthContext";
import { PresenceProvider } from "./contexts/PresenceContext";
import { NotificationProvider } from "./contexts/NotificationContext";
import { ThemeProvider, CssBaseline, Box } from "@mui/material";
import { createTheme } from "@mui/material/styles";
import { indigo } from "@mui/material/colors";
import Navigation from "./components/common/Navigation";
// Permissions
import PrivateRoute from "./components/permissions/PrivateRoute";
import PrivateTeacherRoute from "./components/permissions/PrivateTeacherRoute";
// Auth
import Login from "./components/Login";
import SignUp from "./components/SignUp";
// Profile
import Profile from "./components/profile/Profile";
import EditProfile from "./components/profile/EditProfile";
// Courses
import Courses from "./components/courses/Courses";
import CreateCourse from "./components/courses/CreateCourse";
import Course from "./components/courses/Course";
import EditCourse from "./components/courses/EditCourse";
import MyCourses from "./components/courses/MyCourses";
// Topics
import CreateTopic from "./components/topics/CreateTopic";
import EditTopic from "./components/topics/EditTopic";
import Topic from "./components/topics/Topic";
// Users
import Users from "./components/users/Users";
// Chat
import ChatList from "./components/chat/ChatList";
import ChatRoom from "./components/chat/ChatRoom";
import NotFound from "./components/common/NotFound";
// Fonts
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";

// Create theme
const theme = createTheme({
  palette: {
    primary: {
      light: indigo[300],
      main: indigo[500],
      dark: indigo[700],
      contrastText: "#fff",
    },
    background: {
      default: "#f5f5f5",
    },
  },
  components: {},
});

const AppContent = () => {
  const location = useLocation();
  const hideNavigation = ["/sign-in", "/sign-up"].includes(location.pathname);

  return (
    <Box
      sx={{
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column",
        pt: hideNavigation ? 0 : "64px",
      }}
    >
      <Navigation hideNavigation={hideNavigation} />
      <Box
        component="main"
        sx={{
          flex: 1,
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Routes>
          <Route path="/sign-in" element={<Login />} />
          <Route path="/sign-up" element={<SignUp />} />
          <Route
            path="/profile/:userId/edit"
            element={
              <PrivateRoute>
                <EditProfile />
              </PrivateRoute>
            }
          />
          <Route
            path="/profile/:userId?"
            element={
              <PrivateRoute>
                <Profile />
              </PrivateRoute>
            }
          />
          <Route path="/" element={<Navigate to="/profile" replace />} />
          <Route
            path="/courses"
            element={
              <PrivateRoute>
                <Courses />
              </PrivateRoute>
            }
          />
          <Route
            path="/courses/create"
            element={
              <PrivateRoute>
                <PrivateTeacherRoute>
                  <CreateCourse />
                </PrivateTeacherRoute>
              </PrivateRoute>
            }
          />
          <Route
            path="/courses/:courseSlug"
            element={
              <PrivateRoute>
                <Course />
              </PrivateRoute>
            }
          />
          <Route
            path="/courses/:courseSlug/edit"
            element={
              <PrivateRoute>
                <PrivateTeacherRoute>
                  <EditCourse />
                </PrivateTeacherRoute>
              </PrivateRoute>
            }
          />
          <Route
            path="/courses/:courseSlug/topics/:topicId"
            element={
              <PrivateRoute>
                <Topic />
              </PrivateRoute>
            }
          />
          <Route
            path="/courses/:courseSlug/topics/create"
            element={
              <PrivateRoute>
                <PrivateTeacherRoute>
                  <CreateTopic />
                </PrivateTeacherRoute>
              </PrivateRoute>
            }
          />
          <Route
            path="/courses/:courseSlug/topics/:topicId/edit"
            element={
              <PrivateRoute>
                <PrivateTeacherRoute>
                  <EditTopic />
                </PrivateTeacherRoute>
              </PrivateRoute>
            }
          />
          <Route
            path="/my-courses"
            element={
              <PrivateRoute>
                <MyCourses />
              </PrivateRoute>
            }
          />
          <Route
            path="/users"
            element={
              <PrivateRoute>
                <PrivateTeacherRoute>
                  <Users />
                </PrivateTeacherRoute>
              </PrivateRoute>
            }
          />
          <Route
            path="/chat"
            element={
              <PrivateRoute>
                <ChatList />
              </PrivateRoute>
            }
          />
          <Route
            path="/chat/:roomId"
            element={
              <PrivateRoute>
                <ChatRoom />
              </PrivateRoute>
            }
          />
          <Route
            path="/chat/new"
            element={
              <PrivateRoute>
                <ChatRoom />
              </PrivateRoute>
            }
          />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </Box>
    </Box>
  );
};

function App() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <AuthProvider>
        <PresenceProvider>
          <NotificationProvider>
            <Router>
              <AppContent />
            </Router>
          </NotificationProvider>
        </PresenceProvider>
      </AuthProvider>
    </ThemeProvider>
  );
}

export default App;
