import React, { useState, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../contexts/AuthContext";
import {
  Container,
  Box,
  Typography,
  Paper,
  TextField,
  Button,
  Avatar,
  IconButton,
  Alert,
} from "@mui/material";
import PhotoCamera from "@mui/icons-material/PhotoCamera";
import { stringAvatar } from "../../utils/avatarUtils";
import { getImageUrl } from "../../utils/imageUtils";
import useFormErrors from "../../hooks/useFormErrors";
import { profileService } from "../../services/api";
import LoadingScreen from "../common/LoadingScreen";
import { useNotification } from "../../contexts/NotificationContext";

const EditProfile = () => {
  const { user, updateProfile } = useAuth();
  const navigate = useNavigate();
  const { errors, generalError, clearErrors, clearFieldError, handleErrors } =
    useFormErrors();
  const [loading, setLoading] = useState(true);
  const [formData, setFormData] = useState({
    username: "",
    email: "",
    full_name: "",
    bio: "",
    phone_number: "",
  });
  const [previewImage, setPreviewImage] = useState(null);
  const [imageFile, setImageFile] = useState(null);
  const { showNotification } = useNotification();

  const fetchProfile = useCallback(async () => {
    try {
      const response = await profileService.getProfile(user.id);
      setFormData({
        username: response.profile.username || "",
        email: response.profile.email || "",
        full_name: response.profile.full_name || "",
        bio: response.profile.bio || "",
        phone_number: response.profile.phone_number || "",
      });
      setPreviewImage(
        response.profile.profile_picture
          ? getImageUrl(response.profile.profile_picture)
          : null
      );
    } catch (err) {
      handleErrors(err);
    } finally {
      setLoading(false);
    }
  }, [user.id, handleErrors]);

  useEffect(() => {
    fetchProfile();
  }, [fetchProfile]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
    clearFieldError(name);
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      if (file.size > 5 * 1024 * 1024) {
        handleErrors({
          response: {
            data: {
              errors: {
                profile_picture: ["File size should be less than 5MB"],
              },
            },
          },
        });
        return;
      }
      if (!file.type.startsWith("image/")) {
        handleErrors({
          response: {
            data: {
              errors: { profile_picture: ["Please upload an image file"] },
            },
          },
        });
        return;
      }
      setImageFile(file);
      setPreviewImage(URL.createObjectURL(file));
      clearFieldError("profile_picture");
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    clearErrors();

    try {
      const data = new FormData();
      Object.keys(formData).forEach((key) => {
        data.append(key, formData[key]);
      });
      if (imageFile) {
        data.append("profile_picture", imageFile);
      }

      const response = await profileService.updateProfile(user.id, data);
      if (response.profile) {
        updateProfile(response.profile);
      }

      navigate(`/profile/${user.id}`);
      showNotification("Profile updated successfully", "success");
    } catch (err) {
      handleErrors(err);
    }
  };

  if (loading) {
    return <LoadingScreen />;
  }

  return (
    <Container maxWidth="sm">
      <Box sx={{ py: 4 }}>
        <Paper elevation={2} sx={{ p: 4 }}>
          <Typography variant="h4" gutterBottom align="center">
            Edit Profile
          </Typography>

          {generalError && (
            <Alert severity="error" sx={{ mb: 3 }}>
              {generalError}
            </Alert>
          )}

          <Box
            component="form"
            onSubmit={handleSubmit}
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 3,
            }}
          >
            {/* Profile Picture Upload */}
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                gap: 2,
              }}
            >
              {previewImage ? (
                <Avatar
                  src={
                    imageFile ? URL.createObjectURL(imageFile) : previewImage
                  }
                  sx={{ width: 150, height: 150 }}
                />
              ) : (
                <Avatar
                  {...stringAvatar(user.full_name)}
                  sx={{ width: 150, height: 150 }}
                />
              )}
              <input
                accept="image/*"
                type="file"
                id="profile-picture-input"
                onChange={handleImageChange}
                style={{ display: "none" }}
              />
              <label htmlFor="profile-picture-input">
                <IconButton
                  color="primary"
                  aria-label="upload picture"
                  component="span"
                  sx={{ bgcolor: "rgba(0, 0, 0, 0.04)" }}
                >
                  <PhotoCamera />
                </IconButton>
              </label>
              {errors.profile_picture && (
                <Typography color="error" variant="caption">
                  {errors.profile_picture}
                </Typography>
              )}
            </Box>
            <TextField
              fullWidth
              label="Username"
              name="username"
              value={formData.username}
              onChange={handleChange}
              error={!!errors.username}
              helperText={errors.username}
              required
            />
            <TextField
              fullWidth
              label="Email"
              name="email"
              type="email"
              value={formData.email}
              onChange={handleChange}
              error={!!errors.email}
              helperText={errors.email}
              required
            />
            <TextField
              fullWidth
              label="Full Name"
              name="full_name"
              value={formData.full_name}
              onChange={handleChange}
              error={!!errors.full_name}
              helperText={errors.full_name}
            />
            <TextField
              fullWidth
              label="Bio"
              name="bio"
              value={formData.bio}
              onChange={handleChange}
              multiline
              rows={4}
              error={!!errors.bio}
              helperText={errors.bio}
            />
            <TextField
              fullWidth
              label="Phone Number"
              name="phone_number"
              value={formData.phone_number}
              onChange={handleChange}
              error={!!errors.phone_number}
              helperText={errors.phone_number}
            />
            <Box sx={{ display: "flex", gap: 2, justifyContent: "flex-end" }}>
              <Button disabled={loading} type="submit" variant="contained">
                Save Changes
              </Button>
              <Button
                disabled={loading}
                variant="outlined"
                onClick={() => navigate(`/profile/${user.id}`)}
              >
                Cancel
              </Button>
            </Box>
          </Box>
        </Paper>
      </Box>
    </Container>
  );
};

export default EditProfile;

