import React, { createContext, useContext, useState, useEffect } from "react";
import { authService } from "../services/api";

const AuthContext = createContext(null);

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const accessToken = localStorage.getItem("accessToken");
    if (accessToken) {
      setUser(JSON.parse(localStorage.getItem("userData")));
    }
    setLoading(false);
  }, []);

  const signIn = async (credentials) => {
    const data = await authService.signIn(credentials);
    localStorage.setItem("userData", JSON.stringify(data.user));
    setUser(data.user);
    return data;
  };

  const signUp = async (userData) => {
    const data = await authService.signUp(userData);
    localStorage.setItem("userData", JSON.stringify(data.user));
    setUser(data.user);
    return data;
  };

  const signOut = async () => {
    await authService.signOut();
    localStorage.removeItem("userData");
    setUser(null);
  };

  const updateProfile = (profileData) => {
    setUser((prev) => ({
      ...prev,
      username: profileData.username || prev.username,
      email: profileData.email || prev.email,
      created_at: profileData.created_at || prev.created_at,
      full_name: profileData.full_name || prev.full_name,
      profile_picture: profileData.profile_picture || prev.profile_picture,
    }));
  };

  const value = {
    user,
    updateProfile,
    loading,
    signIn,
    signUp,
    signOut,
    isAuthenticated: authService.isAuthenticated,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
};
