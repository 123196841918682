import React from "react";
import { Box, Typography } from "@mui/material";
import { format } from "date-fns";
import MessageBubble from "./MessageBubble";

const MessageList = ({ messages, currentUserId, messagesEndRef }) => {
  // Group messages by date
  const groupMessagesByDate = () => {
    const groups = {};

    messages.forEach((message) => {
      const date = new Date(message.created_at);
      const dateString = format(date, "yyyy-MM-dd");

      if (!groups[dateString]) {
        groups[dateString] = [];
      }

      groups[dateString].push(message);
    });

    return groups;
  };

  const messageGroups = groupMessagesByDate();

  // Format date for display
  const formatDateHeader = (dateString) => {
    const today = new Date();
    const yesterday = new Date(today);
    yesterday.setDate(yesterday.getDate() - 1);

    const messageDate = new Date(dateString);

    if (format(today, "yyyy-MM-dd") === dateString) {
      return "Today";
    } else if (format(yesterday, "yyyy-MM-dd") === dateString) {
      return "Yesterday";
    } else {
      return format(messageDate, "MMMM d, yyyy");
    }
  };

  return (
    <Box
      sx={{
        flex: 1,
        minHeight: "calc(100vh - 64px)",
        maxHeight: "100%",
        p: 2,
        pb: "100px",
        pt: "72px",
        display: "flex",
        flexDirection: "column",
      }}
    >
      {Object.keys(messageGroups).map((dateString) => (
        <Box key={dateString}>
          {/* Date separator */}
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              my: 2,
            }}
          >
            <Typography
              variant="caption"
              sx={{
                bgcolor: "rgba(0, 0, 0, 0.04)",
                px: 2,
                py: 0.5,
                borderRadius: 4,
                color: "text.secondary",
              }}
            >
              {formatDateHeader(dateString)}
            </Typography>
          </Box>

          {/* Messages for this date */}
          {messageGroups[dateString].map((message, index) => {
            const isSender = message.sender === currentUserId;
            const prevMessage =
              index > 0 ? messageGroups[dateString][index - 1] : null;
            const nextMessage =
              index < messageGroups[dateString].length - 1
                ? messageGroups[dateString][index + 1]
                : null;

            const isFirstInGroup =
              !prevMessage || prevMessage.sender !== message.sender;
            const isLastInGroup =
              !nextMessage || nextMessage.sender !== message.sender;

            return (
              <MessageBubble
                key={message.id}
                message={message}
                isSender={isSender}
                isFirstInGroup={isFirstInGroup}
                isLastInGroup={isLastInGroup}
              />
            );
          })}
        </Box>
      ))}
      <div ref={messagesEndRef} />
    </Box>
  );
};

export default MessageList;

