const WS_PROTOCOL = window.location.protocol === "https:" ? "wss:" : "ws:";
const WS_PORT = process.env.REACT_APP_WS_PORT || "8000";
const WS_HOST = `${
  process.env.REACT_APP_WS_HOST || window.location.hostname
}:${WS_PORT}`;

export const websocketConfig = {
  getWebSocketUrl: (path) => `${WS_PROTOCOL}//${WS_HOST}${path}`,
  getReconnectDelay: (retries) => {
    return Math.min(1000 * Math.pow(2, retries), 30000);
  },
};
