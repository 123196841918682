import { useState, useCallback } from "react";

// Hook for handling paginated data loading with "Load More" functionality
const useLoadMore = ({ fetchData, pageSize = 10, initialData = [] }) => {
  const [data, setData] = useState(initialData);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [isInitialLoading, setIsInitialLoading] = useState(true);
  const [error, setError] = useState(null);

  // Core loading function for fetching items
  const loadItems = useCallback(
    async (pageNum = 1, append = false) => {
      try {
        // Set initial loading state on first load
        if (!append) setIsInitialLoading(true);
        setIsLoading(append);
        setError(null);

        const response = await fetchData({
          page: pageNum,
          page_size: pageSize,
        });

        // Append new data or replace existing data
        if (append) {
          setData((prev) => [...prev, ...response.results]);
        } else {
          setData(response.results);
        }

        // Check if more data is available
        setHasMore(!!response.next);
        setPage(pageNum);
      } catch (err) {
        setError(err);
      } finally {
        setIsInitialLoading(false);
        setIsLoading(false);
      }
    },
    [fetchData, pageSize]
  );

  // Handler for "Load More" button click
  const loadMore = useCallback(() => {
    if (!isLoading && hasMore) {
      loadItems(page + 1, true);
    }
  }, [isLoading, hasMore, page, loadItems]);

  // Handler for refreshing the entire list
  const refresh = useCallback(() => {
    loadItems(1, false);
  }, [loadItems]);

  return {
    data,
    setData,
    isLoading,
    isInitialLoading,
    hasMore,
    error,
    loadMore,
    refresh,
  };
};

export default useLoadMore;
