import React from "react";
import { Chip } from "@mui/material";
import SchoolIcon from "@mui/icons-material/School";
import PersonIcon from "@mui/icons-material/Person";

/**
 * Component for displaying user type (teacher/student) as a chip
 */
const UserTypeChip = ({ userType, large = false, ...props }) => {
  // Select icon based on user type
  const getUserTypeIcon = (type) => {
    return type === "teacher" ? (
      <SchoolIcon fontSize={large ? "small" : "small"} />
    ) : (
      <PersonIcon fontSize={large ? "small" : "small"} />
    );
  };

  return (
    <Chip
      icon={getUserTypeIcon(userType)}
      label={userType}
      size={large ? "medium" : "small"}
      color={userType === "teacher" ? "primary" : "default"}
      variant={large ? "filled" : "filled"}
      {...props}
      sx={{
        textTransform: "capitalize",
        ...(large && {
          fontSize: "0.9rem",
          height: 32,
          paddingX: 1.5,
          "& .MuiChip-label": {
            paddingX: 1.5,
          },
          "& .MuiChip-icon": {
            fontSize: "1.2rem",
          },
        }),
        ...(props.sx ? props.sx : {}),
      }}
    />
  );
};

export default UserTypeChip;
