import React from "react";
import { List, Divider } from "@mui/material";
import { Droppable } from "@hello-pangea/dnd";
import DraggableListItem from "./DraggableListItem";

const DraggableList = ({
  items,
  droppableId,
  draggable,
  editable,
  editUrlBuilder,
  onDelete,
}) => {
  if (draggable) {
    return (
      <Droppable droppableId={droppableId}>
        {(provided) => (
          <List ref={provided.innerRef} {...provided.droppableProps}>
            {items.map((item, index) => (
              <React.Fragment key={item.id}>
                <DraggableListItem
                  item={item}
                  index={index}
                  draggable={true}
                  editable={editable}
                  editUrl={editUrlBuilder(item)}
                />
              </React.Fragment>
            ))}
            {provided.placeholder}
          </List>
        )}
      </Droppable>
    );
  }
  return (
    <List>
      {items.map((item, index) => (
        <React.Fragment key={item.id}>
          <DraggableListItem
            item={item}
            index={index}
            draggable={false}
            editable={editable}
            editUrl={editUrlBuilder(item)}
            onDelete={onDelete}
          />
          {index < items.length - 1 && <Divider component="li" />}
        </React.Fragment>
      ))}
    </List>
  );
};

export default DraggableList;
