import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  CircularProgress,
} from "@mui/material";

const EditStatusDialog = ({
  open,
  onClose,
  onSubmit,
  status,
  onChange,
  isSubmitting,
}) => {
  return (
    <Dialog
      open={open}
      onClose={() => !isSubmitting && onClose()}
      fullWidth
      maxWidth="sm"
      slotProps={{
        paper: {
          component: "form",
          onSubmit: (event) => {
            event.preventDefault();
            onSubmit();
          },
        },
      }}
    >
      <DialogTitle>Edit Status Update</DialogTitle>
      <DialogContent mb={4}>
        <TextField
          autoFocus
          multiline
          minRows={3}
          fullWidth
          variant="standard"
          label="Status update"
          value={status?.content || ""}
          onChange={onChange}
          disabled={isSubmitting}
          required
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} disabled={isSubmitting}>
          Cancel
        </Button>
        <Button
          variant="contained"
          type="submit"
          disabled={isSubmitting}
          startIcon={isSubmitting ? <CircularProgress size={20} /> : null}
        >
          Update
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditStatusDialog;

