import React from "react";
import { TextField, IconButton, Paper } from "@mui/material";
import SendIcon from "@mui/icons-material/Send";

// Message input component with send button
const MessageInput = ({ value, onChange, onSubmit, disabled }) => {
  return (
    // Fixed position form at bottom of chat
    <Paper
      component="form"
      onSubmit={onSubmit}
      sx={{
        position: "fixed",
        bottom: 16,
        left: "50%",
        transform: "translateX(-50%)",
        width: "min(800px, calc(100% - 32px))",
        p: 2,
        borderRadius: 3,
        display: "flex",
        gap: 1,
        backgroundColor: "background.paper",
        zIndex: 1000,
        boxShadow: 3,
      }}
    >
      {/* Message text input field */}
      <TextField
        fullWidth
        placeholder="Type a message..."
        value={value}
        onChange={onChange}
        variant="outlined"
        size="small"
        disabled={disabled}
      />
      {/* Send button */}
      <IconButton type="submit" color="primary" disabled={disabled}>
        <SendIcon />
      </IconButton>
    </Paper>
  );
};

export default MessageInput;
