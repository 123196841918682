import React from "react";
import { Box, Typography, Paper, IconButton } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { Link } from "react-router-dom";
import Avatar from "@mui/material/Avatar";
import { format } from "date-fns";
import { stringAvatar } from "../../utils/avatarUtils";
import { getImageUrl } from "../../utils/imageUtils";

const StatusUpdate = ({ update, isOwner, onEdit, onDelete }) => {
  return (
    <Paper sx={{ p: 2, mb: 2 }}>
      <Box sx={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Link to={`/profile/${update.user_id}`} style={{ textDecoration: "none" }}>
            <Avatar
              src={update.profile_picture ? getImageUrl(update.profile_picture) : null}
              {...(!update.profile_picture && stringAvatar(update.user))}
              sx={{ width: 40, height: 40, mr: 2 }}
            />
          </Link>
          <Box>
            <Link to={`/profile/${update.user_id}`} style={{ textDecoration: "none", color: "inherit" }}>
              <Typography variant="subtitle1" sx={{ fontWeight: "medium" }}>
                {update.full_name || update.user}
              </Typography>
            </Link>
            <Typography variant="caption" color="text.secondary">
              {format(new Date(update.created_at), "MMM d, HH:mm")}
            </Typography>
          </Box>
        </Box>
        {isOwner && (
          <Box>
            <IconButton size="small" onClick={onEdit}>
              <EditIcon fontSize="small" />
            </IconButton>
            <IconButton size="small" onClick={onDelete}>
              <DeleteIcon fontSize="small" />
            </IconButton>
          </Box>
        )}
      </Box>
      <Typography variant="body1" sx={{ mt: 2, pl: 7 }}>
        {update.content}
      </Typography>
    </Paper>
  );
};

export default StatusUpdate;
