import React from "react";
import MDEditor from "@uiw/react-md-editor";
import { Paper, Typography } from "@mui/material";

// WYSIWYG Markdown editor component with preview functionality
const MarkdownEditor = ({ value, onChange, error, label }) => {
  return (
    // Force light theme for consistent appearance
    <div data-color-mode="light">
      {/* Optional label with error state */}
      {label && (
        <Typography
          color={error ? "error" : "inherit"}
          variant="subtitle2"
          sx={{ mb: 1 }}
        >
          {label}
        </Typography>
      )}

      {/* Editor container with border */}
      <Paper variant="outlined" sx={{ mb: error ? 0 : 2 }}>
        <MDEditor
          value={value}
          onChange={(val) =>
            onChange({
              target: { name: "material.content", value: val || "" },
            })
          }
          // Enable preview mode with height limit
          preview="edit"
          height={400}
        />
      </Paper>

      {/* Error message display */}
      {error && (
        <Typography color="error" variant="caption" sx={{ mt: 0.5 }}>
          {error}
        </Typography>
      )}
    </div>
  );
};

export default MarkdownEditor;
