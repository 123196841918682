import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  CircularProgress,
} from "@mui/material";

const DeleteStatusDialog = ({ open, onClose, onConfirm, isSubmitting }) => {
  return (
    <Dialog open={open} onClose={() => !isSubmitting && onClose()}>
      <DialogTitle>Delete Status Update</DialogTitle>
      <DialogContent>
        <Typography>
          Are you sure you want to delete this status update?
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} disabled={isSubmitting}>
          Cancel
        </Button>
        <Button
          color="error"
          variant="contained"
          onClick={onConfirm}
          disabled={isSubmitting}
          startIcon={isSubmitting ? <CircularProgress size={20} /> : null}
        >
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteStatusDialog;
