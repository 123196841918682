import React, { useState, useEffect, useCallback } from "react";
import { useParams, Link } from "react-router-dom";
import {
  Container,
  Paper,
  Typography,
  Box,
  Divider,
  Alert,
  Button,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import ImageIcon from "@mui/icons-material/Image";
import AttachmentIcon from "@mui/icons-material/Attachment";
import { format } from "date-fns";
import { topicService } from "../../services/api";
import { useAuth } from "../../contexts/AuthContext";
import LoadingScreen from "../common/LoadingScreen";
import MarkdownRenderer from "../common/MarkdownRenderer";

const Topic = () => {
  const { courseSlug, topicId } = useParams();
  const { user } = useAuth();
  const [topic, setTopic] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");

  const loadTopic = useCallback(async () => {
    try {
      const response = await topicService.getTopic(courseSlug, topicId);
      setTopic(response);
      setError("");
    } catch (err) {
      setError("Failed to load topic. Please try again later.");
      console.error("Error loading topic:", err);
    } finally {
      setLoading(false);
    }
  }, [courseSlug, topicId]);

  useEffect(() => {
    loadTopic();
  }, [loadTopic]);

  if (loading) {
    return <LoadingScreen />;
  }

  if (!topic) {
    return (
      <Container sx={{ py: 4 }}>
        <Alert severity="error">Topic not found</Alert>
      </Container>
    );
  }

  return (
    <Container maxWidth="lg" sx={{ py: 4 }}>
      <Paper elevation={2} sx={{ p: 4 }}>
        {error && (
          <Alert severity="error" sx={{ mb: 3 }}>
            {error}
          </Alert>
        )}

        <Box sx={{ mb: 4 }}>
          <Box
            sx={{
              display: "flex",
              flexDirection: { xs: "column", sm: "row" },
              justifyContent: "space-between",
              alignItems: { xs: "flex-start", sm: "center" },
            }}
          >
            <Typography variant="h4" gutterBottom sx={{ fontWeight: "bold" }}>
              {topic.title}
            </Typography>
            {user?.id === topic.teacher_id && (
              <Button
                startIcon={<EditIcon />}
                variant="outlined"
                color="primary"
                component={Link}
                to={`/courses/${courseSlug}/topics/${topicId}/edit`}
                sx={{ mt: { xs: 2, sm: 0 } }}
              >
                Edit Topic
              </Button>
            )}
          </Box>
        </Box>

        {topic.material.video_file && (
          <Box
            sx={{
              mb: 2,
            }}
          >
            <video controls width="100%">
              <source src={topic.material.video_file} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </Box>
        )}

        <Box sx={{ mb: 4 }}>
          <Typography
            variant="body1"
            color="text.secondary"
            sx={{ lineHeight: 1.6 }}
          >
            {topic.description}
          </Typography>
        </Box>

        <Divider sx={{ mb: 4 }} />

        <Box sx={{ mt: 4 }}>
          {topic.material ? (
            <Box>
              {topic.material.content && (
                <Box sx={{ mb: 3 }}>
                  <MarkdownRenderer content={topic.material.content} />
                </Box>
              )}

              {/* Add attachments section */}
              {topic.material.attachments &&
                topic.material.attachments.length > 0 && (
                  <Box sx={{ mt: 4 }}>
                    <Typography variant="h6" gutterBottom>
                      Attachments
                    </Typography>
                    <List>
                      {topic.material.attachments.map((att) => (
                        <ListItem
                          key={att.id}
                          component="a"
                          href={att.file}
                          target="_blank"
                          rel="noopener noreferrer"
                          sx={{
                            "&:hover": {
                              backgroundColor: "rgba(0, 0, 0, 0.04)",
                            },
                            cursor: "pointer",
                          }}
                        >
                          <ListItemIcon>
                            {att.file_type === "image" ? (
                              <ImageIcon />
                            ) : (
                              <AttachmentIcon />
                            )}
                          </ListItemIcon>
                          <ListItemText
                            primary={att.title}
                            secondary={`${att.file_size} • Added ${format(
                              new Date(att.created_at),
                              "MMM d, HH:mm"
                            )}`}
                          />
                        </ListItem>
                      ))}
                    </List>
                  </Box>
                )}
            </Box>
          ) : (
            <Typography color="text.secondary">
              No materials available yet.
            </Typography>
          )}
        </Box>
      </Paper>
    </Container>
  );
};

export default Topic;
