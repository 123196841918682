import React from "react";
import { Box, Typography, IconButton, Button } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";

// Video upload component with preview and remove functionality
const VideoUpload = ({
  currentVideo,
  videoFile,
  onChange,
  onRemove,
  error,
}) => {
  return (
    <Box sx={{ mb: 3 }}>
      <Typography variant="subtitle2" sx={{ mb: 1 }}>
        Video Content
      </Typography>
      {currentVideo || videoFile ? (
        <Box sx={{ position: "relative" }}>
          <video
            controls
            width="100%"
            src={videoFile ? URL.createObjectURL(videoFile) : currentVideo}
          >
            Your browser does not support the video tag.
          </video>
          {/* Remove video button */}
          <IconButton
            onClick={onRemove}
            sx={{
              position: "absolute",
              top: 8,
              right: 8,
              backgroundColor: "rgba(0, 0, 0, 0.5)",
              "&:hover": {
                backgroundColor: "rgba(0, 0, 0, 0.7)",
              },
            }}
          >
            <DeleteIcon sx={{ color: "white" }} />
          </IconButton>
        </Box>
      ) : (
        // Upload video button
        <Button
          component="label"
          variant="outlined"
          startIcon={<CloudUploadIcon />}
          sx={{ mb: error ? 0 : 2 }}
        >
          Upload Video
          <input
            type="file"
            accept="video/mp4,video/webm"
            hidden
            onChange={(e) =>
              onChange({
                target: {
                  name: "material.video_file",
                  files: e.target.files,
                },
              })
            }
          />
        </Button>
      )}
      {/* Error message display */}
      {error && (
        <Typography
          color="error"
          variant="caption"
          sx={{ display: "block", mt: 0.5 }}
        >
          {error}
        </Typography>
      )}
    </Box>
  );
};

export default VideoUpload;
