import React, { useState, useEffect, useCallback } from "react";
import { useParams, Link } from "react-router-dom";
import {
  Container,
  Paper,
  Typography,
  Box,
  Divider,
  Alert,
  Button,
  Chip,
  Link as InlineLink,
  Tabs,
  Tab,
} from "@mui/material";
import PersonIcon from "@mui/icons-material/Person";
import GroupIcon from "@mui/icons-material/Group";
import EditIcon from "@mui/icons-material/Edit";
import PeopleIcon from "@mui/icons-material/People";
import FeedbackIcon from "@mui/icons-material/Feedback";
import { courseService } from "../../services/api";
import { useAuth } from "../../contexts/AuthContext";
import LoadingScreen from "../common/LoadingScreen";
import DraggableList from "./DraggableList";
import CourseFeedback from "./CourseFeedback";
import Rating from "../common/Rating";
import CourseEnrolledUsers from "./CourseEnrolledUsers";
import { useNotification } from "../../contexts/NotificationContext";

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`course-tabpanel-${index}`}
      aria-labelledby={`course-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ py: 3 }}>{children}</Box>}
    </div>
  );
}

const Course = () => {
  const { courseSlug } = useParams();
  const { user } = useAuth();
  const [course, setCourse] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [currentTab, setCurrentTab] = useState(0);
  const { showNotification } = useNotification();
  const isCourseOwner = user?.id === course?.teacher;

  const loadCourse = useCallback(async () => {
    try {
      const courseResponse = await courseService.getCourse(courseSlug);
      setCourse(courseResponse);
      setError("");
    } catch (err) {
      setError("Failed to load course. Please try again later.");
      console.error("Error loading course:", err);
    } finally {
      setLoading(false);
    }
  }, [courseSlug]);

  useEffect(() => {
    loadCourse();
  }, [courseSlug, loadCourse]);

  const handleEnroll = async () => {
    try {
      await courseService.enrollInCourse(courseSlug);
      showNotification("Successfully enrolled in course", "success");

      loadCourse();
    } catch (err) {
      setError("Failed to enroll in course. Please try again.");
      console.error("Error enrolling:", err);
    }
  };

  const handleUnenroll = async () => {
    try {
      await courseService.unenrollFromCourse(courseSlug);
      showNotification("Successfully unenrolled from course");

      loadCourse();
    } catch (err) {
      setError("Failed to unenroll from course. Please try again.");
      console.error("Error unenrolling:", err);
    }
  };

  const handleTabChange = (event, newValue) => {
    setCurrentTab(newValue);
  };

  if (loading) {
    return <LoadingScreen />;
  }

  if (!course) {
    return (
      <Container sx={{ py: 4 }}>
        <Alert severity="error">Course not found</Alert>
      </Container>
    );
  }

  return (
    <Container maxWidth="lg" sx={{ py: 4 }}>
      <Paper elevation={2} sx={{ p: 4 }}>
        {error && (
          <Alert severity="error" sx={{ mb: 3 }}>
            {error}
          </Alert>
        )}

        <Box sx={{ mb: 4 }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "flex-start",
              mb: 2,
            }}
          >
            <Typography variant="h4" gutterBottom>
              {course.title}
            </Typography>
            <Box sx={{ display: "flex", gap: 2 }}>
              {/* Display edit button only for course owner */}
              {isCourseOwner && (
                <Button
                  startIcon={<EditIcon />}
                  variant="outlined"
                  color="primary"
                  component={Link}
                  to={`/courses/${course.slug}/edit`}
                >
                  Edit Course
                </Button>
              )}
              {/* Check if the user is a student */}
              {user?.user_type === "student" && (
                <Button
                  variant="outlined"
                  color={course.is_enrolled ? "error" : "primary"}
                  onClick={course.is_enrolled ? handleUnenroll : handleEnroll}
                  sx={{ minWidth: 100 }}
                >
                  {course.is_enrolled ? "Unenroll" : "Enroll"}
                </Button>
              )}
            </Box>
          </Box>

          <Box sx={{ display: "flex", alignItems: "center", gap: 2, mb: 2 }}>
            <Box sx={{ display: "flex", alignItems: "center", gap: 0.5 }}>
              <PersonIcon fontSize="small" color="action" />
              <InlineLink
                underline="none"
                variant="body2"
                component={Link}
                to={`/profile/${course.teacher}`}
              >
                {course.teacher_name}
              </InlineLink>
            </Box>
            <Box sx={{ display: "flex", alignItems: "center", gap: 0.5 }}>
              <GroupIcon fontSize="small" color="action" />
              <Typography variant="body2" color="text.secondary">
                {course.student_count} students
              </Typography>
            </Box>
            <Rating rating={course.avg_rating} />
            {course.is_enrolled && (
              <Chip
                label="Enrolled"
                size="small"
                color="primary"
                variant="outlined"
              />
            )}
          </Box>
        </Box>

        <Divider sx={{ my: 3 }} />

        <Box sx={{ mb: 4 }}>
          <Typography variant="h6" gutterBottom>
            Description
          </Typography>
          <Typography variant="body1" color="text.secondary">
            {course.description}
          </Typography>
        </Box>

        <Box sx={{ mt: 4 }}>
          <Typography variant="h6" gutterBottom>
            Course Content
          </Typography>

          {course.topics?.length > 0 ? (
            <Box sx={{ mt: 2 }}>
              <DraggableList
                items={course.topics}
                droppableId="topics"
                draggable={false}
                editable={false}
                editUrlBuilder={(item) =>
                  `/courses/${courseSlug}/topics/${item.id}`
                }
              />
            </Box>
          ) : (
            <Typography color="text.secondary">
              No topics available yet.
            </Typography>
          )}
        </Box>
      </Paper>

      <Box sx={{ borderBottom: 1, borderColor: "divider", mt: 4 }}>
        <Tabs
          value={currentTab}
          onChange={handleTabChange}
          aria-label="course content tabs"
        >
          <Tab
            icon={<FeedbackIcon />}
            iconPosition="start"
            label="Feedback"
            id="course-tab-0"
          />
          {isCourseOwner && (
            <Tab
              icon={<PeopleIcon />}
              iconPosition="start"
              label="Students"
              id="course-tab-1"
            />
          )}
        </Tabs>
      </Box>

      <TabPanel value={currentTab} index={0}>
        <CourseFeedback
          courseSlug={courseSlug}
          isEnrolled={course.is_enrolled}
          isStudent={user?.user_type === "student"}
          avgRating={course.avg_rating}
          onFeedbackChange={loadCourse}
        />
      </TabPanel>
      {/* Display enrolled users tab only for course owner */}
      {isCourseOwner && (
        <TabPanel value={currentTab} index={1}>
          <CourseEnrolledUsers
            courseSlug={courseSlug}
            teacherId={course.teacher}
          />
        </TabPanel>
      )}
    </Container>
  );
};

export default Course;

