import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from "@mui/material";

const DeleteTopicDialog = ({ open, onClose, onConfirm, topicTitle }) => {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Delete Topic</DialogTitle>
      <DialogContent>
        Are you sure you want to delete the «{topicTitle}» topic? This action
        cannot be undone.
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={onConfirm} color="error" variant="contained">
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteTopicDialog;
