import React, { useState, useEffect, useCallback } from "react";
import { useNavigate, useParams, Link } from "react-router-dom";
import {
  Container,
  Paper,
  Typography,
  TextField,
  Button,
  Box,
  Alert,
  FormControlLabel,
  Switch,
  Divider,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import { courseService, topicService } from "../../services/api";
import useFormErrors from "../../hooks/useFormErrors";
import LoadingScreen from "../common/LoadingScreen";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import { DragDropContext } from "@hello-pangea/dnd";
import DraggableList from "./DraggableList";
import { useNotification } from "../../contexts/NotificationContext";
import { useAuth } from "../../contexts/AuthContext";

const EditCourse = () => {
  const navigate = useNavigate();
  const { courseSlug } = useParams();
  const { showNotification } = useNotification();
  const { user } = useAuth();
  const [formData, setFormData] = useState({
    title: "",
    description: "",
    topics: [],
    is_published: true,
  });
  const [loading, setLoading] = useState(true);
  const { errors, generalError, clearFieldError, handleErrors } =
    useFormErrors();
  const [courseToDelete, setCourseToDelete] = useState(null);

  const loadCourse = useCallback(async () => {
    try {
      const response = await courseService.getCourse(courseSlug);
      if (user.id !== response.teacher) navigate(`/courses/${courseSlug}`);
      setFormData({
        title: response.title,
        description: response.description,
        topics: response.topics,
        is_published: response.is_published,
      });
    } catch (err) {
      handleErrors(err);
    } finally {
      setLoading(false);
    }
  }, [courseSlug, handleErrors, user.id, navigate]);

  useEffect(() => {
    loadCourse();
  }, [loadCourse]);

  const handleChange = (e) => {
    const { name, value, checked } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: name === "is_published" ? checked : value,
    }));
    clearFieldError(name);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      await courseService.updateCourse(courseSlug, formData);
      showNotification("Course successfully updated!", "success");
      navigate(`/courses/${courseSlug}`);
    } catch (err) {
      handleErrors(err);
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteCourse = async () => {
    try {
      await courseService.deleteCourse(courseSlug);
      showNotification(
        `Course "${courseToDelete?.title}" has been deleted`,
        "success"
      );
      setCourseToDelete(null);
      navigate("/courses");
    } catch (err) {
      handleErrors(err);
    }
  };

  const onDragEnd = async (result) => {
    if (!result.destination) return;

    const items = Array.from(formData.topics);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    setFormData((prev) => ({
      ...prev,
      topics: items,
    }));

    try {
      const topicId = parseInt(result.draggableId, 10);
      await topicService.updateTopicOrder(courseSlug, {
        topic_id: topicId,
        new_order: result.destination.index,
      });
    } catch (err) {
      handleErrors(err);
      loadCourse();
    }
  };

  if (loading) {
    return <LoadingScreen />;
  }

  return (
    <Container maxWidth="lg" sx={{ py: 4 }}>
      <Paper
        elevation={2}
        sx={{ p: 4 }}
        component="form"
        onSubmit={handleSubmit}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            mb: 2,
          }}
        >
          <Typography variant="h4" pb={2}>
            Edit Course
          </Typography>
          <Button
            variant="outlined"
            color="error"
            startIcon={<DeleteIcon />}
            onClick={() => setCourseToDelete(formData)}
          >
            Delete Course
          </Button>
        </Box>

        {generalError && (
          <Alert severity="error" sx={{ mb: 3 }}>
            {generalError}
          </Alert>
        )}

        <Box sx={{ mt: 2 }}>
          <TextField
            label="Course Title"
            name="title"
            value={formData.title}
            onChange={handleChange}
            fullWidth
            required
            error={!!errors.title}
            helperText={errors.title}
            sx={{ mb: 3 }}
          />

          <TextField
            label="Course Description"
            name="description"
            value={formData.description}
            onChange={handleChange}
            fullWidth
            required
            multiline
            rows={4}
            error={!!errors.description}
            helperText={errors.description}
            sx={{ mb: 3 }}
          />

          <FormControlLabel
            control={
              <Switch
                checked={formData.is_published}
                onChange={handleChange}
                name="is_published"
              />
            }
            label="Published"
            sx={{ mb: 3 }}
          />
        </Box>

        <Divider sx={{ my: 4 }} />

        <Box sx={{ mt: 4 }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              mb: 2,
            }}
          >
            <Typography variant="h6">Course Content</Typography>
            <Button
              variant="outlined"
              startIcon={<AddIcon />}
              component={Link}
              to={`/courses/${courseSlug}/topics/create`}
            >
              Add Topic
            </Button>
          </Box>

          {formData.topics?.length > 0 ? (
            <DragDropContext onDragEnd={onDragEnd}>
              <DraggableList
                items={formData.topics}
                droppableId="topics"
                draggable={true}
                editable={true}
                editUrlBuilder={(item) =>
                  `/courses/${courseSlug}/topics/${item.id}/edit`
                }
              />
            </DragDropContext>
          ) : (
            <Typography color="text.secondary">
              No topics available yet.
            </Typography>
          )}
          <Divider sx={{ my: 4 }} />
          <Box sx={{ display: "flex", gap: 2, justifyContent: "flex-end" }}>
            <Button type="submit" variant="contained">
              Save Changes
            </Button>
            <Button
              variant="outlined"
              onClick={() => navigate(`/courses/${courseSlug}`)}
            >
              Cancel
            </Button>
          </Box>
        </Box>
      </Paper>

      {/* Course Delete Confirmation Dialog */}
      <Dialog open={!!courseToDelete} onClose={() => setCourseToDelete(null)}>
        <DialogTitle>Delete Course</DialogTitle>
        <DialogContent>
          Are you sure you want to delete the «{courseToDelete?.title}» course?
          This action cannot be undone.
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setCourseToDelete(null)}>Cancel</Button>
          <Button
            onClick={handleDeleteCourse}
            color="error"
            variant="contained"
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default EditCourse;
