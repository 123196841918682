import React from "react";
import {
  ListItem,
  ListItemAvatar,
  Avatar,
  Typography,
  Paper,
  Box,
} from "@mui/material";
import { stringAvatar } from "../../utils/avatarUtils";
import { getImageUrl } from "../../utils/imageUtils";
import { useNavigate } from "react-router-dom";
import UserTypeChip from "../common/UserTypeChip";

const UserListItem = ({ user }) => {
  const navigate = useNavigate();

  return (
    <Paper
      elevation={1}
      onClick={() => navigate(`/profile/${user.id}`)}
      sx={{
        mb: 2,
        overflow: "hidden",
        transition: "transform 0.2s, box-shadow 0.2s",
        cursor: "pointer",
        "&:hover": {
          transform: "translateY(-2px)",
          boxShadow: 2,
          bgcolor: "action.hover",
        },
      }}
    >
      <ListItem sx={{ px: 3, py: 2 }}>
        <ListItemAvatar>
          <Avatar
            src={getImageUrl(user.profile_picture)}
            alt={user.full_name}
            {...(!user.profile_picture ? stringAvatar(user.full_name) : {})}
            sx={{
              width: 60,
              height: 60,
              fontSize: "1.5rem",
            }}
          />
        </ListItemAvatar>
        <Box sx={{ ml: 2, flex: 1 }}>
          <Box sx={{ display: "flex", alignItems: "center", gap: 1, mb: 0.5 }}>
            <Typography
              variant="h6"
              component="div"
              sx={{ textTransform: "capitalize" }}
            >
              {user.full_name}
            </Typography>
            <UserTypeChip userType={user.user_type} sx={{ ml: 1 }} />
          </Box>
          <Typography
            variant="body2"
            color="text.secondary"
            sx={{ mb: 0.5, fontWeight: 500 }}
          >
            @{user.username}
          </Typography>
        </Box>
      </ListItem>
    </Paper>
  );
};

export default UserListItem;

