import React, { useState, useCallback, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import TopicForm from "./TopicForm";
import useFormErrors from "../../hooks/useFormErrors";
import { topicService } from "../../services/api";
import { useNotification } from "../../contexts/NotificationContext";
import { courseService } from "../../services/api";
import { useAuth } from "../../contexts/AuthContext";

const CreateTopic = () => {
  const navigate = useNavigate();
  const { user } = useAuth();
  const { courseSlug } = useParams();
  const { showNotification } = useNotification();
  const [formData, setFormData] = useState({
    title: "",
    description: "",
    material: { content: "", video_file: null, current_video: null },
  });
  const [attachments, setAttachments] = useState([]);
  const [loading, setLoading] = useState(false);
  const { errors, generalError, clearFieldError, handleErrors } =
    useFormErrors();
  const editCourseUrl = `/courses/${courseSlug}/edit`;

  const loadCourse = useCallback(async () => {
    try {
      const response = await courseService.getCourse(courseSlug);
      if (user.id !== response.teacher) navigate(`/courses/${courseSlug}`);
    } catch (err) {
      handleErrors(err);
    } finally {
      setLoading(false);
    }
  }, [courseSlug, handleErrors, user.id, navigate]);

  useEffect(() => {
    loadCourse();
  }, [loadCourse]);

  const handleChange = (e) => {
    const { name, value, files } = e.target;
    if (name === "material.video_file") {
      setFormData((prev) => ({
        ...prev,
        material: { ...prev.material, video_file: files[0] },
      }));
    } else if (name.startsWith("material.")) {
      const key = name.split(".")[1];
      setFormData((prev) => ({
        ...prev,
        material: { ...prev.material, [key]: value },
      }));
    } else {
      setFormData((prev) => ({ ...prev, [name]: value }));
    }
    clearFieldError(name);
  };

  const onFileSelect = (e) => {
    const files = Array.from(e.target.files);
    const newAtts = files.map((file) => ({
      file,
      title: file.name,
      type: file.type.startsWith("image/") ? "image" : "pdf",
    }));
    setAttachments((prev) => [...prev, ...newAtts]);
  };

  const handleRemoveAttachment = (index) => {
    setAttachments((prev) => prev.filter((_, i) => i !== index));
  };

  const handleAttachmentTitleChange = (index, newTitle) => {
    setAttachments((prev) =>
      prev.map((att, i) => (i === index ? { ...att, title: newTitle } : att))
    );
  };

  const handleRemoveVideo = () => {
    setFormData((prev) => ({
      ...prev,
      material: { ...prev.material, video_file: null, current_video: null },
    }));
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      // Prepare FormData for multipart if attachments or video_file exist
      const hasFiles = formData.material.video_file || attachments.length > 0;
      if (hasFiles) {
        const data = new FormData();
        data.append("title", formData.title);
        data.append("description", formData.description);
        data.append("material.content", formData.material.content);
        if (formData.material.video_file) {
          data.append("material.video_file", formData.material.video_file);
        }
        // Append attachments
        attachments.forEach((att) => {
          data.append("uploaded_files", att.file);
          data.append("attachment_titles", att.title);
        });
        await topicService.createTopic(courseSlug, data, true);
      } else {
        await topicService.createTopic(courseSlug, formData);
      }
      showNotification("Topic successfully created!", "success");
      navigate(editCourseUrl);
    } catch (err) {
      handleErrors(err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <TopicForm
      formTitle="Create Topic"
      formData={formData}
      attachments={attachments}
      errors={errors}
      generalError={generalError}
      loading={loading}
      onChange={handleChange}
      onFileSelect={onFileSelect}
      handleRemoveAttachment={handleRemoveAttachment}
      handleAttachmentTitleChange={handleAttachmentTitleChange}
      handleRemoveVideo={handleRemoveVideo}
      onSubmit={onSubmit}
      actionLabel="Create Topic"
      cancelAction={() => navigate(editCourseUrl)}
    />
  );
};

export default CreateTopic;
