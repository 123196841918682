import { useState, useEffect, useCallback, useRef } from "react";
import { useNotification } from "../contexts/NotificationContext";
import { websocketConfig } from "../utils/websocketUtils";

export const useChat = (roomId, onMessageReceived) => {
  const [socket, setSocket] = useState(null);
  const [socketReady, setSocketReady] = useState(false);
  const reconnectTimeoutRef = useRef(null);
  const { showNotification } = useNotification();

  const connect = useCallback(() => {
    // Don't connect if roomId is null or undefined
    if (!roomId) {
      setSocketReady(false);
      setSocket(null);
      return;
    }
    const token = localStorage.getItem("accessToken");
    const ws = new WebSocket(
      websocketConfig.getWebSocketUrl(`/ws/chat/${roomId}/?token=${token}`)
    );
    const notificationOptions = {
      autoHideDuration: 1000,
    };

    ws.onopen = () => {
      setSocketReady(true);
      showNotification("Connected to chat", "success", notificationOptions);
    };

    ws.onmessage = (event) => {
      try {
        const data = JSON.parse(event.data);
        if (data.error) {
          showNotification(data.error, "error", notificationOptions);
          return;
        }
        if (data.type === "chat_message") {
          onMessageReceived?.(data.message);
        }
      } catch (error) {
        console.error("Failed to parse message:", error);
      }
    };

    ws.onclose = (event) => {
      setSocketReady(false);
      // Only attempt reconnection if the roomId is valid and the closure wasn't intentional
      if (roomId && !event.wasClean) {
        showNotification(
          "Chat connection lost. Reconnecting...",
          "warning",
          notificationOptions
        );
        reconnectTimeoutRef.current = setTimeout(connect, 5000);
      }
    };

    ws.onerror = () => {
      showNotification(
        "Connection error. Retrying...",
        "error",
        notificationOptions
      );
      ws.close();
    };

    setSocket(ws);
  }, [roomId, showNotification, onMessageReceived]);

  useEffect(() => {
    // Clean up previous connection before establishing a new one
    if (socket) {
      socket.close();
    }
    if (reconnectTimeoutRef.current) {
      clearTimeout(reconnectTimeoutRef.current);
    }

    connect();

    return () => {
      if (socket) {
        // Remove all event listeners
        socket.onopen = null;
        socket.onmessage = null;
        socket.onclose = null;
        socket.onerror = null;
        // Close the connection
        socket.close();
      }
      if (reconnectTimeoutRef.current) {
        clearTimeout(reconnectTimeoutRef.current);
      }
    };
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [connect]);

  return { socket, socketReady };
};
