import React, { useState, useCallback } from "react";
import {
  Container,
  TextField,
  Typography,
  List,
  InputAdornment,
  Button,
  Box,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import PeopleIcon from "@mui/icons-material/People";
import CircularProgress from "@mui/material/CircularProgress";
import { userService } from "../../services/api";
import { useDebounce } from "../../hooks/useDebounce";
import UserListItem from "./UserListItem";
import EmptyState from "../common/EmptyState";
import LoadingSpinner from "../common/LoadingSpinner";
import useLoadMore from "../../hooks/useLoadMore";

/**
 * Users directory component with search and pagination functionality
 * Only accessible to teachers
 */
const Users = () => {
  // Search state with debounce to prevent excessive API calls
  const [searchQuery, setSearchQuery] = useState("");
  const debouncedSearch = useDebounce(searchQuery, 500);

  // Fetch users from API and transform response to match useLoadMore hook expectations
  const fetchUsers = useCallback(
    async (params = {}) => {
      const response = await userService.getUsers({
        search: debouncedSearch,
        ...params,
      });

      // Transform response to expected format for useLoadMore
      return {
        results: response.users || [],
        next: response.next,
        previous: response.previous,
        count: response.count,
      };
    },
    [debouncedSearch]
  );

  // Use the loadMore hook for paginated data handling
  const {
    data: users,
    isLoading,
    isInitialLoading,
    hasMore,
    loadMore,
    refresh,
  } = useLoadMore({
    fetchData: fetchUsers,
    pageSize: 5,
  });

  // Refresh data when search query changes
  React.useEffect(() => {
    refresh();
  }, [debouncedSearch, refresh]);

  return (
    <Container maxWidth="md" sx={{ py: 4 }}>
      <Typography variant="h4" component="h1" gutterBottom>
        Users Directory
      </Typography>

      {/* Search input */}
      <TextField
        fullWidth
        variant="outlined"
        placeholder="Search by name or username..."
        value={searchQuery}
        onChange={(e) => setSearchQuery(e.target.value)}
        sx={{ mb: 4 }}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
        }}
      />

      {/* Conditional rendering based on loading state and data availability */}
      {isInitialLoading ? (
        <LoadingSpinner />
      ) : users && users.length > 0 ? (
        <>
          {/* User list */}
          <List sx={{ width: "100%" }}>
            {users.map((user) => (
              <UserListItem key={user.id} user={user} />
            ))}
          </List>
          {/* Load more button - only shown if more data is available */}
          {hasMore && (
            <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
              <Button
                onClick={loadMore}
                disabled={isLoading}
                variant="outlined"
                fullWidth
              >
                {isLoading ? <CircularProgress size={24} /> : "Load More Users"}
              </Button>
            </Box>
          )}
        </>
      ) : (
        // Empty state when no users are found
        <EmptyState
          icon={PeopleIcon}
          title={searchQuery ? "No Users Found" : "No Users Available"}
          description={
            searchQuery
              ? "Try adjusting your search terms or criteria"
              : "Start searching to find users in the directory"
          }
        />
      )}
    </Container>
  );
};

export default Users;
