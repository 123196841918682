import React, { useState, useEffect } from "react";
import { Paper, Typography, List, Alert } from "@mui/material";
import { courseService } from "../../services/api";
import { useNotification } from "../../contexts/NotificationContext";
import LoadingSpinner from "../common/LoadingSpinner";
import CourseStudentListItem from "./CourseStudentListItem";
import EmptyState from "../common/EmptyState";
import PeopleIcon from "@mui/icons-material/People";
import RemoveStudentDialog from "../common/RemoveStudentDialog";
import { useAuth } from "../../contexts/AuthContext";

const CourseEnrolledUsers = ({ courseSlug, teacherId }) => {
  const { user } = useAuth();
  const { showNotification } = useNotification();
  const [enrolledUsers, setEnrolledUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedStudent, setSelectedStudent] = useState(null);
  const [removeDialogOpen, setRemoveDialogOpen] = useState(false);
  const [error, setError] = useState("");

  useEffect(() => {
    const fetchEnrolledUsers = async () => {
      try {
        setLoading(true);
        const response = await courseService.getEnrolledUsers(courseSlug);
        setEnrolledUsers(response.users);
      } catch (error) {
        setError("Failed to load enrolled students");
        console.error("Error fetching enrolled users:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchEnrolledUsers();
  }, [courseSlug]);

  const handleRemoveStudent = async () => {
    try {
      await courseService.removeStudent(courseSlug, selectedStudent.id);
      showNotification(
        `Student ${selectedStudent.full_name} has been removed from the course`,
        "success"
      );
      setEnrolledUsers((users) =>
        users.filter((u) => u.id !== selectedStudent.id)
      );
      setRemoveDialogOpen(false);
    } catch (error) {
      showNotification("Failed to remove student from the course", "error");
      console.error("Error removing student:", error);
    }
  };

  const openRemoveDialog = (student) => {
    setSelectedStudent(student);
    setRemoveDialogOpen(true);
  };

  if (loading) {
    return <LoadingSpinner />;
  }

  if (!enrolledUsers.length) {
    return (
      <EmptyState
        icon={PeopleIcon}
        title="No Enrolled Users"
        description="This course doesn't have any enrolled students yet."
      />
    );
  }

  return (
    <Paper elevation={2} sx={{ p: 4 }}>
      {error && (
        <Alert severity="error" sx={{ mb: 2 }}>
          {error}
        </Alert>
      )}

      <Typography variant="h6" gutterBottom>
        Enrolled Students ({enrolledUsers.length})
      </Typography>

      <List sx={{ mt: 2 }}>
        {enrolledUsers.map((student) => (
          <CourseStudentListItem
            key={student.id}
            user={student}
            isTeacher={user?.id === teacherId}
            onRemoveStudent={openRemoveDialog}
          />
        ))}
      </List>

      <RemoveStudentDialog
        open={removeDialogOpen}
        onClose={() => setRemoveDialogOpen(false)}
        onConfirm={handleRemoveStudent}
        studentName={selectedStudent?.full_name}
      />
    </Paper>
  );
};

export default CourseEnrolledUsers;
