import React, { useState, useEffect, useCallback } from "react";
import { Paper, Typography, Box, Button, Avatar, Divider } from "@mui/material";
import { courseService } from "../../services/api";
import { stringAvatar } from "../../utils/avatarUtils";
import FeedbackDialog from "./FeedbackDialog";
import ConfirmDialog from "../common/ConfirmDialog";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import IconButton from "@mui/material/IconButton";
import Rating from "../common/Rating";
import LoadingSpinner from "../common/LoadingSpinner";
import { getImageUrl } from "../../utils/imageUtils";

const CourseFeedback = ({
  courseSlug,
  isEnrolled,
  isStudent,
  avgRating,
  onFeedbackChange,
}) => {
  const [open, setOpen] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [loading, setLoading] = useState(true);
  // eslint-disable-next-line
  const [error, setError] = useState("");
  const [feedbackList, setFeedbackList] = useState([]);
  const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false);
  const [selectedFeedback, setSelectedFeedback] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [userFeedback, setUserFeedback] = useState(null);

  const loadFeedback = useCallback(async () => {
    try {
      setLoading(true);
      setError("");
      // Load the user's feedback and all feedback for the course
      const [userResponse, allResponse] = await Promise.all([
        isStudent
          ? courseService.getFeedback(courseSlug, { current: true })
          : Promise.resolve({ feedback: [] }),
        courseService.getFeedback(courseSlug),
      ]);

      // Get the user's feedback if they have already submitted one
      const userFeedback = userResponse?.length > 0 ? userResponse[0] : null;
      // Filter out the user's feedback from the list of all feedback
      const allFeedback =
        allResponse?.filter((f) => !userFeedback || f.id !== userFeedback.id) ||
        [];

      setFeedbackList(allFeedback);
      setUserFeedback(userFeedback);
    } catch (err) {
      setError("Failed to load feedback");
      console.error("Error loading feedback:", err);
    } finally {
      setLoading(false);
    }
  }, [courseSlug, isStudent]);

  useEffect(() => {
    loadFeedback();
  }, [courseSlug, loadFeedback]);

  const handleEditClick = (feedback) => {
    setSelectedFeedback(feedback);
    setIsEditing(true);
    setOpen(true);
  };

  const handleDeleteClick = (feedback) => {
    setSelectedFeedback(feedback);
    setDeleteConfirmOpen(true);
    loadFeedback();
  };

  const handleSubmitFeedback = async (feedbackData) => {
    try {
      setSubmitting(true);
      if (isEditing && selectedFeedback) {
        await courseService.updateFeedback(
          courseSlug,
          selectedFeedback.id,
          feedbackData
        );
      } else {
        await courseService.submitFeedback(courseSlug, feedbackData);
      }
      setOpen(false);
      setIsEditing(false);
      setSelectedFeedback(null);
      loadFeedback();
      onFeedbackChange?.();
    } catch (error) {
      console.error("Error submitting feedback:", error);
    } finally {
      setSubmitting(false);
    }
  };

  const handleDelete = async () => {
    try {
      setSubmitting(true);
      if (selectedFeedback) {
        await courseService.deleteFeedback(courseSlug, selectedFeedback.id);
        setDeleteConfirmOpen(false);
        loadFeedback();
        onFeedbackChange?.();
      }
    } catch (error) {
      console.error("Error deleting feedback:", error);
    } finally {
      setSubmitting(false);
    }
  };

  const handleDialogClose = () => {
    setOpen(false);
    setIsEditing(false);
    setSelectedFeedback(null);
  };

  if (loading) {
    return (
      <Paper elevation={2} sx={{ p: 4, mt: 3 }}>
        <LoadingSpinner />
      </Paper>
    );
  }

  return (
    <Paper elevation={2} sx={{ p: 4, mt: 3 }}>
      {/* Header with overall feedback summary */}
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          mb: 3,
        }}
      >
        <Box>
          <Typography variant="h6" gutterBottom>
            Student Feedback
          </Typography>
          <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
            <Rating rating={avgRating} size="medium" />
          </Box>
        </Box>
        {isStudent &&
          (isEnrolled ? (
            !userFeedback && (
              <Button variant="contained" onClick={() => setOpen(true)}>
                Leave Feedback
              </Button>
            )
          ) : (
            <Typography variant="body2" color="text.secondary">
              Only enrolled students can leave feedback
            </Typography>
          ))}
      </Box>
      {/* Current user's feedback */}
      {userFeedback && (
        <>
          <Box sx={{ display: "flex", gap: 2, mb: 3 }}>
            <Avatar
              src={getImageUrl(userFeedback.student_picture)}
              alt={userFeedback.student_name}
              {...(!userFeedback.student_picture
                ? stringAvatar(userFeedback.student_name)
                : {})}
            />
            <Box sx={{ flex: 1 }}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Typography variant="subtitle1">
                  {userFeedback.student_name}
                </Typography>
                {isEnrolled && (
                  <Box>
                    <IconButton
                      onClick={() => handleEditClick(userFeedback)}
                      size="small"
                    >
                      <EditIcon fontSize="small" />
                    </IconButton>
                    <IconButton
                      onClick={() => handleDeleteClick(userFeedback)}
                      size="small"
                    >
                      <DeleteIcon fontSize="small" />
                    </IconButton>
                  </Box>
                )}
              </Box>
              <Rating rating={userFeedback.rating} showNumber={false} />
              <Typography variant="body2" sx={{ mt: 1 }}>
                {userFeedback.comment}
              </Typography>
            </Box>
          </Box>
          <Divider sx={{ my: 3 }} />
        </>
      )}
      {/* Feedback list from other students */}
      {feedbackList.length > 0 && (
        <>
          {feedbackList.map((feedback, index) => (
            <React.Fragment key={feedback.id}>
              {index > 0 && <Divider sx={{ my: 2 }} />}
              <Box sx={{ display: "flex", gap: 2 }}>
                <Avatar
                  src={getImageUrl(feedback.student_picture)}
                  alt={feedback.student_name}
                  {...(!feedback.student_picture
                    ? stringAvatar(feedback.student_name)
                    : {})}
                />
                <Box sx={{ flex: 1 }}>
                  <Typography variant="subtitle1">
                    {feedback.student_name}
                  </Typography>
                  <Rating rating={feedback.rating} showNumber={false} />
                  <Typography variant="body2" sx={{ mt: 1 }}>
                    {feedback.comment}
                  </Typography>
                </Box>
              </Box>
            </React.Fragment>
          ))}
        </>
      )}
      {/* Show message when no feedback available */}
      {!userFeedback && feedbackList.length === 0 && (
        <Box sx={{ textAlign: "center", py: 4 }}>
          <Typography variant="body1" color="text.secondary">
            This course hasn't received any feedback yet.
          </Typography>
        </Box>
      )}
      {/* Dialog for adding or editing feedback */}
      <FeedbackDialog
        open={open}
        onClose={handleDialogClose}
        onSubmit={handleSubmitFeedback}
        submitting={submitting}
        initialData={isEditing ? selectedFeedback : undefined}
      />
      {/* Confirm dialog for deleting feedback */}
      <ConfirmDialog
        open={deleteConfirmOpen}
        title="Delete Feedback"
        message="Are you sure you want to delete your feedback? This action cannot be undone."
        onConfirm={handleDelete}
        onClose={() => setDeleteConfirmOpen(false)}
        confirmText="Delete"
      />
    </Paper>
  );
};

export default CourseFeedback;

