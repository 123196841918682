import React from "react";
import {
  ListItem,
  ListItemText,
  ListItemAvatar,
  Avatar,
  Typography,
  IconButton,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { stringAvatar } from "../../utils/avatarUtils";
import { getImageUrl } from "../../utils/imageUtils";
import UserTypeChip from "../common/UserTypeChip";
import PersonRemoveIcon from "@mui/icons-material/PersonRemove";

const CourseStudentListItem = ({ user, isTeacher, onRemoveStudent }) => {
  const navigate = useNavigate();

  const handleClick = (e) => {
    // Don't navigate if clicking the remove button
    if (e.target.closest(".remove-button")) {
      e.stopPropagation();
      return;
    }
    navigate(`/profile/${user.id}`);
  };

  return (
    <ListItem
      onClick={handleClick}
      sx={{
        mb: 1,
        borderRadius: 1,
        border: "1px solid",
        borderColor: "divider",
        backgroundColor: "background.paper",
        cursor: "pointer",
        "&:hover": {
          backgroundColor: "action.hover",
        },
      }}
      secondaryAction={
        isTeacher && (
          <IconButton
            className="remove-button"
            edge="end"
            aria-label="remove student"
            onClick={(e) => {
              e.stopPropagation();
              onRemoveStudent(user);
            }}
            size="small"
            color="error"
          >
            <PersonRemoveIcon />
          </IconButton>
        )
      }
    >
      <ListItemAvatar>
        <Avatar
          src={getImageUrl(user.profile_picture)}
          alt={user.full_name}
          {...(!user.profile_picture ? stringAvatar(user.full_name) : {})}
          sx={{ width: 40, height: 40 }}
        />
      </ListItemAvatar>
      <ListItemText
        primary={
          <Typography variant="subtitle1" sx={{ textTransform: "capitalize" }}>
            {user.full_name}
            <UserTypeChip userType={user.user_type} sx={{ ml: 1 }} />
          </Typography>
        }
        secondary={
          <Typography variant="body2" color="text.secondary">
            @{user.username}
          </Typography>
        }
        sx={{
          "& .MuiListItemText-primary": {
            fontWeight: 500,
            color: "text.primary",
          },
        }}
      />
    </ListItem>
  );
};

export default CourseStudentListItem;
