import React, { useState, useEffect, useCallback } from "react";
import {
  IconButton,
  Badge,
  Popover,
  Box,
  Typography,
  Button,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import NotificationsIcon from "@mui/icons-material/Notifications";
import { notificationService } from "../../services/api";
import { formatDistanceToNow } from "date-fns";
import { useAuth } from "../../contexts/AuthContext";

const NotificationMenu = () => {
  // State for notifications data and popover anchor
  const { user } = useAuth();
  const [notifications, setNotifications] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  // Memoized notification fetch function to prevent unnecessary re-renders
  const loadNotifications = useCallback(async () => {
    try {
      const response = await notificationService.getNotifications();
      setNotifications(response);
    } catch (error) {
      console.error("Failed to load notifications:", error);
    }
  }, []);

  // Set up notification polling
  useEffect(() => {
    if (user?.id) {
      loadNotifications();
      // Poll every minute
      const interval = setInterval(loadNotifications, 60000);
      return () => clearInterval(interval);
    }
  }, [loadNotifications, user?.id]);

  // Popover handlers
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  // Mark all notifications as read and refresh the list
  const handleMarkAllRead = async () => {
    try {
      await notificationService.markAllRead();
      loadNotifications();
    } catch (error) {
      console.error("Failed to mark notifications as read:", error);
    }
  };

  // Calculate number of unread notifications for badge
  const unreadCount = notifications.filter((n) => !n.is_read).length;

  return (
    <>
      {/* Notification Bell Icon with Badge */}
      <IconButton color="inherit" onClick={handleClick} size="large">
        <Badge badgeContent={unreadCount} color="error">
          <NotificationsIcon />
        </Badge>
      </IconButton>
      {/* Notification Popover Panel */}
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <Box sx={{ width: 360, maxHeight: 400 }}>
          {/* Popover Header with Mark All Read Option */}
          <Box
            sx={{
              p: 2,
              display: "flex",
              justifyContent: "space-between",
              borderBottom: 1,
              borderColor: "divider",
            }}
          >
            <Typography variant="h6">Notifications</Typography>
            {unreadCount > 0 && (
              <Button size="small" onClick={handleMarkAllRead}>
                Mark all read
              </Button>
            )}
          </Box>
          {/* Scrollable Notification List */}
          <List sx={{ maxHeight: 300, overflow: "auto" }}>
            {notifications.length === 0 ? (
              <ListItem>
                <ListItemText primary="No notifications" />
              </ListItem>
            ) : (
              notifications.map((notification) => (
                // Individual notification item with read/unread styling
                <ListItem
                  key={notification.id}
                  sx={{
                    backgroundColor: notification.is_read
                      ? "inherit"
                      : "action.hover",
                  }}
                >
                  <ListItemText
                    primary={notification.title}
                    secondary={
                      <>
                        {/* Notification message and timestamp */}
                        <Typography
                          variant="body2"
                          component="span"
                          display="block"
                        >
                          {notification.message}
                        </Typography>
                        <Typography variant="caption" color="text.secondary">
                          {formatDistanceToNow(
                            new Date(notification.created_at),
                            { addSuffix: true }
                          )}
                        </Typography>
                      </>
                    }
                  />
                </ListItem>
              ))
            )}
          </List>
        </Box>
      </Popover>
    </>
  );
};

export default NotificationMenu;
