import React from "react";
import { Link } from "react-router-dom";
import {
  Card,
  CardContent,
  Typography,
  Box,
  Chip,
  Divider,
  Link as InlineLink,
} from "@mui/material";
import PersonIcon from "@mui/icons-material/Person";
import GroupIcon from "@mui/icons-material/Group";
import Rating from "../common/Rating";

const CourseListItem = ({
  title,
  description,
  slug,
  teacherId,
  teacherName,
  studentCount,
  isEnrolled,
  avgRating,
}) => {
  return (
    <Card
      sx={{
        mb: 2,
        overflow: "hidden",
        transition: "transform 0.2s, box-shadow 0.2s",
        "&:hover": {
          transform: "translateY(-2px)",
          boxShadow: 2,
        },
      }}
    >
      <CardContent>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "flex-start",
            mb: 2,
          }}
        >
          <Box>
            <Typography variant="h5" gutterBottom>
              <InlineLink
                variant="h5"
                color="textPrimary"
                underline="none"
                component={Link}
                to={`/courses/${slug}`}
              >
                {title}
              </InlineLink>
            </Typography>
            <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <PersonIcon fontSize="small" color="action" />
                <InlineLink
                  underline="none"
                  variant="body2"
                  component={Link}
                  to={`/profile/${teacherId}`}
                >
                  {teacherName}
                </InlineLink>
              </Box>
              <Typography color="text.secondary">•</Typography>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <GroupIcon fontSize="small" color="action" />
                <Typography
                  variant="body2"
                  color="text.secondary"
                  sx={{ ml: 0.5 }}
                >
                  {studentCount} students
                </Typography>
              </Box>
              <Typography color="text.secondary">•</Typography>
              <Rating rating={avgRating} />
              {isEnrolled && (
                <>
                  <Typography color="text.secondary">•</Typography>
                  <Chip
                    label="Enrolled"
                    size="small"
                    color="primary"
                    variant="outlined"
                  />
                </>
              )}
            </Box>
          </Box>
        </Box>
        <Divider sx={{ my: 2 }} />
        <Typography variant="body1" color="text.secondary">
          {description}
        </Typography>
      </CardContent>
    </Card>
  );
};

export default CourseListItem;
