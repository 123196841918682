import React, { useState, useEffect, useCallback } from "react";
import { Box, Typography, Button, CircularProgress } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { statusUpdateService } from "../../services/api";
import StatusUpdate from "./StatusUpdate";
import CreateStatusDialog from "./CreateStatusDialog";
import EditStatusDialog from "./EditStatusDialog";
import DeleteStatusDialog from "./DeleteStatusDialog";
import LoadingSpinner from "../common/LoadingSpinner";
import { useNotification } from "../../contexts/NotificationContext";
import useLoadMore from "../../hooks/useLoadMore";

const StatusUpdates = ({ username, currentUserId, isOwner }) => {
  const { showNotification } = useNotification();
  const [openModal, setOpenModal] = useState(false);
  const [newStatus, setNewStatus] = useState("");
  const [editingStatus, setEditingStatus] = useState(null);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [deleteConfirmation, setDeleteConfirmation] = useState(null);

  const fetchStatusUpdates = useCallback(
    async (params) => {
      return await statusUpdateService.getStatusUpdates({
        user: username,
        ...params,
      });
    },
    [username]
  );

  const {
    data: statusUpdates,
    isLoading,
    isInitialLoading,
    hasMore,
    loadMore,
    refresh,
  } = useLoadMore({
    fetchData: fetchStatusUpdates,
    pageSize: 3,
  });

  useEffect(() => {
    if (username) {
      refresh();
    }
  }, [username, refresh]);

  const handleStatusSubmit = async () => {
    try {
      if (!newStatus.trim()) return;
      setIsSubmitting(true);
      await statusUpdateService.createStatusUpdate({ content: newStatus });
      showNotification("Status update successfully created!", "success");
      setNewStatus("");
      setOpenModal(false);
      refresh();
    } catch (err) {
      showNotification("Failed to create status update", "error");
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleEditSubmit = async () => {
    try {
      if (!editingStatus.content.trim()) return;
      setIsSubmitting(true);
      await statusUpdateService.updateStatusUpdate(editingStatus.id, {
        content: editingStatus.content,
      });
      showNotification("Status update successfully saved!", "success");
      setEditingStatus(null);
      setOpenEditModal(false);
      refresh();
    } catch (err) {
      showNotification("Failed to update status update", "error");
      console.error("Error updating status update:", err);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleDelete = async (updateId) => {
    try {
      setIsSubmitting(true);
      await statusUpdateService.deleteStatusUpdate(updateId);
      showNotification("Status update successfully deleted!", "success");
      refresh();
      setDeleteConfirmation(null);
    } catch (err) {
      showNotification("Failed to delete status update", "error");
      console.error("Error deleting status update:", err);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <>
      <Box
        sx={{
          mt: 2,
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography variant="h6" gutterBottom>
          Recent Status Updates
        </Typography>
        {isOwner && (
          <Button
            variant="contained"
            onClick={() => setOpenModal(true)}
            startIcon={<AddIcon />}
          >
            Update Status
          </Button>
        )}
      </Box>
      <Box sx={{ mt: 2 }}>
        {isInitialLoading ? (
          <LoadingSpinner />
        ) : statusUpdates.length === 0 ? (
          <Typography>No status updates available</Typography>
        ) : (
          <>
            {statusUpdates.map((update) => (
              <StatusUpdate
                key={update.id}
                update={update}
                isOwner={update.user_id === currentUserId}
                onEdit={() => {
                  setEditingStatus(update);
                  setOpenEditModal(true);
                }}
                onDelete={() => setDeleteConfirmation(update)}
              />
            ))}

            {hasMore && (
              <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
                <Button
                  onClick={loadMore}
                  disabled={isLoading}
                  variant="outlined"
                >
                  {isLoading ? <CircularProgress size={24} /> : "Load More"}
                </Button>
              </Box>
            )}
          </>
        )}
      </Box>

      <CreateStatusDialog
        open={openModal}
        onClose={() => setOpenModal(false)}
        onSubmit={handleStatusSubmit}
        value={newStatus}
        onChange={(e) => setNewStatus(e.target.value)}
        isSubmitting={isSubmitting}
      />

      <EditStatusDialog
        open={openEditModal}
        onClose={() => setOpenEditModal(false)}
        onSubmit={handleEditSubmit}
        status={editingStatus}
        onChange={(e) =>
          setEditingStatus((prev) => ({ ...prev, content: e.target.value }))
        }
        isSubmitting={isSubmitting}
      />

      <DeleteStatusDialog
        open={!!deleteConfirmation}
        onClose={() => setDeleteConfirmation(null)}
        onConfirm={() => handleDelete(deleteConfirmation?.id)}
        isSubmitting={isSubmitting}
      />
    </>
  );
};

export default StatusUpdates;
