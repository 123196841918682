import React, { useState, useEffect, useCallback } from "react";
import { useParams } from "react-router-dom";
import { useAuth } from "../../contexts/AuthContext";
import { Container, Box } from "@mui/material";
import { profileService } from "../../services/api";
import LoadingScreen from "../common/LoadingScreen";
import StatusUpdates from "./StatusUpdates";
import ProfileInfo from "./ProfileInfo";
import ProfileCourseList from "./ProfileCourseList";

const Profile = () => {
  const { userId } = useParams();
  const { user } = useAuth();
  const [profile, setProfile] = useState(null);
  const [loading, setLoading] = useState(true);
  // eslint-disable-next-line
  const [error, setError] = useState("");

  const loadProfile = useCallback(async () => {
    try {
      setLoading(true);
      const targetUserId = userId || user.id;
      const response = await profileService.getProfile(targetUserId);
      setProfile(response.profile);
      setError("");
    } catch (err) {
      setError("Failed to load profile");
      console.error("Error loading profile:", err);
    } finally {
      setLoading(false);
    }
  }, [userId, user?.id]);

  useEffect(() => {
    loadProfile();
  }, [loadProfile]);

  if (loading) return <LoadingScreen />;

  return (
    <Container maxWidth="lg">
      <Box sx={{ py: 4, display: "flex", gap: 3 }}>
        <Box sx={{ flex: 1 }}>
          <ProfileInfo profile={profile} isOwner={user?.id === profile?.id} />
        </Box>
        <Box sx={{ flex: 2 }}>
          <StatusUpdates
            username={profile?.username}
            currentUserId={user?.id}
            isOwner={user?.id === profile?.id}
          />
          <ProfileCourseList
            userId={profile?.id}
            userType={profile?.user_type}
          />
        </Box>
      </Box>
    </Container>
  );
};

export default Profile;
