import React, { useState, useEffect, useCallback } from "react";
import { Link } from "react-router-dom";
import {
  Alert,
  Container,
  Grid,
  Typography,
  Button,
  Box,
  TextField,
  InputAdornment,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { useAuth } from "../../contexts/AuthContext";
import { courseService } from "../../services/api";
import AddIcon from "@mui/icons-material/Add";
import CourseListItem from "./CourseListItem";
import EmptyState from "../common/EmptyState";
import { useDebounce } from "../../hooks/useDebounce";
import LoadingSpinner from "../common/LoadingSpinner";

const MyCourses = () => {
  const [courses, setCourses] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const debouncedSearch = useDebounce(searchQuery, 500);
  const { user } = useAuth();
  const isTeacher = user?.user_type === "teacher";

  const loadCourses = useCallback(async () => {
    try {
      setLoading(true);
      const params = {
        ...(isTeacher
          ? { created_by: user.id }
          : { enrolled_student: user.id }),
        ...(debouncedSearch ? { search: debouncedSearch } : {}),
      };
      const response = await courseService.getCourses(params);
      setCourses(response);
      setError("");
    } catch (err) {
      setError("Failed to load courses. Please try again later.");
      console.error("Error loading courses:", err);
    } finally {
      setLoading(false);
    }
  }, [isTeacher, user.id, debouncedSearch]);

  useEffect(() => {
    loadCourses();
  }, [loadCourses]);

  return (
    <Container maxWidth="lg" sx={{ mt: 4, pb: 4 }}>
      <Box sx={{ mb: 4 }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            mb: 3,
          }}
        >
          <Typography variant="h4">
            {isTeacher ? "My Published Courses" : "My Current Courses"}
          </Typography>
          {isTeacher && (
            <Button
              variant="contained"
              component={Link}
              to="/courses/create"
              startIcon={<AddIcon />}
            >
              Create Course
            </Button>
          )}
        </Box>

        <TextField
          fullWidth
          variant="outlined"
          placeholder="Search my courses by title or description..."
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon color="action" />
              </InputAdornment>
            ),
          }}
          sx={{ mb: 2 }}
        />
      </Box>

      {error && (
        <Alert severity="error" sx={{ mb: 3 }}>
          {error}
        </Alert>
      )}

      {loading ? (
        <LoadingSpinner />
      ) : courses.length > 0 ? (
        <Grid container spacing={3}>
          {courses.map((course) => (
            <Grid item xs={12} key={course.id}>
              <CourseListItem
                title={course.title}
                description={course.description}
                slug={course.slug}
                teacherId={course.teacher}
                teacherName={course.teacher_name}
                studentCount={course.student_count}
                isEnrolled={course.is_enrolled}
                avgRating={course.avg_rating}
              />
            </Grid>
          ))}
        </Grid>
      ) : (
        <EmptyState
          title={isTeacher ? "No Courses Created Yet" : "No Enrolled Courses"}
          description={
            isTeacher
              ? "Create your first course and start sharing your knowledge!"
              : "Browse available courses and start learning something new!"
          }
          actionText={isTeacher ? "Create Course" : "Browse Courses"}
          actionLink={isTeacher ? "/courses/create" : "/courses"}
          actionIcon={isTeacher ? AddIcon : null}
        />
      )}
    </Container>
  );
};

export default MyCourses;
