import { useState, useCallback } from "react";

const useFormErrors = () => {
  const [errors, setErrors] = useState({});
  const [generalError, setGeneralError] = useState("");

  const clearErrors = useCallback(() => {
    setErrors({});
    setGeneralError("");
  }, []);

  const clearFieldError = useCallback((fieldName) => {
    setErrors((prev) => ({
      ...prev,
      [fieldName]: null,
    }));
  }, []);

  const setFieldError = useCallback((fieldName, errorMessage) => {
    setErrors((prev) => ({
      ...prev,
      [fieldName]: errorMessage,
    }));
  }, []);

  const handleErrors = useCallback(
    (error) => {
      clearErrors();

      const errorData = error.response?.data?.errors;
      if (!errorData) {
        setGeneralError("An unexpected error occurred");
        return;
      }

      const { non_field_errors, ...fieldErrors } = errorData;

      // Handle field-specific errors
      const normalizedErrors = Object.entries(fieldErrors).reduce(
        (acc, [field, messages]) => ({
          ...acc,
          [field]: Array.isArray(messages) ? messages[0] : messages,
        }),
        {}
      );

      setErrors(normalizedErrors);

      // Handle non-field errors
      if (non_field_errors) {
        setGeneralError(
          Array.isArray(non_field_errors)
            ? non_field_errors.join(" ")
            : non_field_errors
        );
      }
    },
    [clearErrors]
  );

  return {
    errors,
    generalError,
    clearErrors,
    clearFieldError,
    setFieldError,
    handleErrors,
  };
};

export default useFormErrors;
