import React, { useState, useEffect, useRef, useCallback } from "react";
import { Paper, Container } from "@mui/material";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import { chatService } from "../../services/api";
import { useAuth } from "../../contexts/AuthContext";
import { useNotification } from "../../contexts/NotificationContext";
import { useChat } from "../../hooks/useChat";
import ChatHeader from "./ChatHeader";
import MessageList from "./MessageList";
import MessageInput from "./MessageInput";
import LoadingScreen from "../common/LoadingScreen";
import EmptyState from "../common/EmptyState";

// Main chat room component handling messages, socket connections, and UI
const ChatRoom = () => {
  const { roomId } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const { user } = useAuth();
  const { showNotification } = useNotification();
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState("");
  const messagesEndRef = useRef(null);
  const [loading, setLoading] = useState(true);
  const [room, setRoom] = useState(null);

  // WebSocket message handler - updates messages and removes pending status
  const handleMessageReceived = useCallback((data) => {
    setMessages((prev) => {
      const filtered = prev.filter((msg) => !msg.pending);
      return [...filtered, data];
    });
  }, []);

  // Auto-scroll to bottom when new messages arrive
  useEffect(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [messages]);

  // Initialize WebSocket connection when room is available
  const { socket, socketReady } = useChat(room?.id, handleMessageReceived);

  // API call to get room details
  const fetchRoom = useCallback(async (id) => {
    try {
      const chatRoom = await chatService.getChatRoom(id);
      if (!chatRoom) {
        throw new Error("Chat room not found");
      }
      return chatRoom;
    } catch (error) {
      console.error("Error fetching room:", error);
      throw error;
    }
  }, []);

  // Handle creation of new chat rooms
  const handleNewChat = useCallback(async () => {
    const userId = new URLSearchParams(location.search).get("userId");
    if (!userId) {
      throw new Error("Invalid user selected");
    }
    const response = await chatService.createChatRoom({
      participants: [parseInt(userId)],
    });
    return response;
  }, [location.search]);

  // Initialize chat room - handles both new and existing rooms
  useEffect(() => {
    let mounted = true;

    const initializeChat = async () => {
      try {
        setLoading(true);
        let chatRoom;

        // Check if creating a new chat room or joining an existing one
        if (location.pathname === "/chat/new") {
          chatRoom = await handleNewChat();
          if (mounted) {
            navigate(`/chat/${chatRoom.id}`, { replace: true });
          }
        } else if (roomId) {
          chatRoom = await fetchRoom(roomId);
        }

        if (!chatRoom || !mounted) return;
        setRoom(chatRoom);
        // Fetch existing messages for the chat room
        const messages = await chatService.getChatMessages(chatRoom.id);
        if (mounted) {
          setMessages(messages);
        }
      } catch (err) {
        console.error("Chat initialization error:", err);
        if (mounted) {
          showNotification(err.message || "Failed to initialize chat", "error");
          navigate("/chat");
        }
      } finally {
        if (mounted) {
          setLoading(false);
        }
      }
    };

    initializeChat();

    return () => {
      mounted = false;
    };
  }, [
    roomId,
    location.pathname,
    navigate,
    showNotification,
    fetchRoom,
    handleNewChat,
  ]);

  // Handle message sending through WebSocket
  const handleSend = (e) => {
    e.preventDefault();

    if (!newMessage.trim() || !socket || !socketReady || !room.id) return;

    try {
      // Add temporary message to UI until server confirms
      const tempMessage = {
        id: `temp-${Date.now()}`,
        content: newMessage.trim(),
        sender: user.id,
        created_at: new Date().toISOString(),
        pending: true,
      };
      setMessages((prev) => [...prev, tempMessage]);
      // Send message to server
      socket.send(
        JSON.stringify({
          type: "chat_message",
          content: newMessage.trim(),
          room_id: room.id,
          sender_id: user.id,
        })
      );
      // Clear input field
      setNewMessage("");
    } catch (error) {
      showNotification("Failed to send message", "error");
      socket?.close();
    }
  };

  if (loading) return <LoadingScreen />;

  if (!room) {
    return (
      <EmptyState
        title="Chat Room Not Available"
        description="The chat room you're looking for is not available at the moment."
        sx={{ mt: "130px" }}
      />
    );
  }

  return (
    <Paper elevation={2} sx={{ display: "flex", flexDirection: "column" }}>
      {/* Chat room header with participant info */}
      <ChatHeader room={room} userId={user?.id} />
      <Container maxWidth="lg">
        {/* Message list with auto-scroll functionality */}
        <MessageList
          messages={messages}
          currentUserId={user.id}
          messagesEndRef={messagesEndRef}
        />
        {/* Message input form */}
        <MessageInput
          value={newMessage}
          onChange={(e) => setNewMessage(e.target.value)}
          onSubmit={handleSend}
          disabled={!socketReady}
        />
      </Container>
    </Paper>
  );
};

export default ChatRoom;
