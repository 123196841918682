import React from "react";
import { Navigate } from "react-router-dom";
import { useAuth } from "../../contexts/AuthContext";
import LoadingScreen from "../common/LoadingScreen";

const PrivateRoute = ({ children }) => {
  const { isAuthenticated, loading } = useAuth();

  // Render a loading screen while the user is being authenticated
  if (loading) {
    return <LoadingScreen />;
  }

  return isAuthenticated() ? children : <Navigate to="/sign-in" />;
};

export default PrivateRoute;
