import React from "react";
import { Link as RouterLink } from "react-router-dom";
import { Typography, Link, Box } from "@mui/material";
import Groups2Icon from "@mui/icons-material/Groups2";

// Component for displaying the app logo with optional size variant
const Logo = ({ variant = "default" }) => {
  const styles = {
    default: {
      fontSize: "1.5rem",
      fontWeight: 700,
      color: "white",
      letterSpacing: "0.1em",
      textTransform: "uppercase",
    },
    large: {
      fontSize: "2.5rem",
      fontWeight: 700,
      color: "primary.main",
      letterSpacing: "0.1em",
      textTransform: "uppercase",
    },
  };

  const iconSize = variant === "large" ? "3.5rem" : "2rem";

  return (
    <Link
      component={RouterLink}
      to="/"
      sx={{
        textDecoration: "none",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: variant === "large" ? "100%" : "auto",
      }}
      mb={variant === "large" ? 2 : 0}
    >
      <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
        <Groups2Icon
          sx={{ fontSize: iconSize, color: styles[variant].color }}
        />
        <Typography
          variant={variant === "large" ? "h3" : "h6"}
          component="div"
          sx={styles[variant]}
        >
          Lurn
        </Typography>
      </Box>
    </Link>
  );
};

export default Logo;
