import React, { useState } from "react";
import {
  Container,
  Paper,
  Typography,
  TextField,
  Button,
  Box,
  Alert,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  IconButton,
  Divider,
} from "@mui/material";
import AttachmentIcon from "@mui/icons-material/Attachment";
import ImageIcon from "@mui/icons-material/Image";
import DeleteIcon from "@mui/icons-material/Delete";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import VideoUpload from "../common/VideoUpload";
import DeleteTopicDialog from "./DeleteTopicDialog";
import MarkdownEditor from "../common/MarkdownEditor";

const TopicForm = ({
  formTitle,
  formData,
  attachments,
  errors,
  generalError,
  loading,
  onChange,
  onFileSelect,
  handleRemoveAttachment,
  handleAttachmentTitleChange,
  handleRemoveVideo,
  onSubmit,
  onDelete,
  actionLabel,
  cancelAction,
}) => {
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);

  return (
    <Container maxWidth="md" sx={{ py: 4 }}>
      <Paper elevation={2} sx={{ p: 4 }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            mb: 2,
          }}
        >
          <Typography variant="h4" gutterBottom>
            {formTitle}
          </Typography>
          {onDelete && (
            <Button
              variant="outlined"
              color="error"
              startIcon={<DeleteIcon />}
              onClick={() => setDeleteDialogOpen(true)}
            >
              Delete Topic
            </Button>
          )}
        </Box>
        {generalError && (
          <Alert severity="error" sx={{ mb: 3 }}>
            {generalError}
          </Alert>
        )}
        <Box component="form" onSubmit={onSubmit} sx={{ mt: 2 }}>
          <TextField
            label="Topic Title"
            name="title"
            value={formData.title}
            onChange={onChange}
            fullWidth
            required
            error={!!errors.title}
            helperText={errors.title}
            sx={{ mb: 3 }}
          />
          <TextField
            label="Topic Description"
            name="description"
            value={formData.description}
            onChange={onChange}
            fullWidth
            required
            multiline
            rows={4}
            error={!!errors.description}
            helperText={errors.description}
            sx={{ mb: 3 }}
          />
          <MarkdownEditor
            label="Topic Content*"
            value={formData.material.content}
            onChange={onChange}
            error={errors["material.content"]}
          />
          <VideoUpload
            currentVideo={formData.material.current_video}
            videoFile={formData.material.video_file}
            onChange={onChange}
            onRemove={handleRemoveVideo}
            error={errors["material.video_file"]}
          />
          {/* Attachments Section */}
          <Box sx={{ mt: 3 }}>
            <Typography variant="subtitle2" gutterBottom>
              Attachments
            </Typography>
            <Button
              component="label"
              variant="outlined"
              startIcon={<FileUploadIcon />}
              sx={{ mb: 2 }}
            >
              Upload Files
              <input
                type="file"
                hidden
                multiple
                accept=".pdf,image/*"
                onChange={onFileSelect}
              />
            </Button>
            <List>
              {attachments.map((att, index) => (
                <ListItem
                  key={index}
                  secondaryAction={
                    <IconButton
                      edge="end"
                      onClick={() => handleRemoveAttachment(index)}
                    >
                      <DeleteIcon />
                    </IconButton>
                  }
                >
                  <ListItemIcon>
                    {att.type === "image" ? <ImageIcon /> : <AttachmentIcon />}
                  </ListItemIcon>
                  <ListItemText
                    primary={
                      <TextField
                        value={att.title}
                        onChange={(e) =>
                          handleAttachmentTitleChange(index, e.target.value)
                        }
                        variant="standard"
                        fullWidth
                        placeholder="Enter title"
                      />
                    }
                    secondary={`${(att.file.size / 1024 / 1024).toFixed(2)} MB`}
                  />
                </ListItem>
              ))}
            </List>
          </Box>
          <Divider sx={{ my: 4 }} />
          <Box
            sx={{ display: "flex", gap: 2, justifyContent: "flex-end", mt: 3 }}
          >
            <Button type="submit" variant="contained" disabled={loading}>
              {actionLabel}
            </Button>
            <Button
              variant="outlined"
              onClick={cancelAction}
              disabled={loading}
            >
              Cancel
            </Button>
          </Box>
        </Box>
        {onDelete && (
          <DeleteTopicDialog
            open={deleteDialogOpen}
            onClose={() => setDeleteDialogOpen(false)}
            onConfirm={onDelete}
            topicTitle={formData.title}
          />
        )}
      </Paper>
    </Container>
  );
};

export default TopicForm;
