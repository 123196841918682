import React, { useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import { useAuth } from "../../contexts/AuthContext";
import {
  AppBar,
  Toolbar,
  Typography,
  Box,
  Container,
  Avatar,
  Menu,
  MenuItem,
  Stack,
  Divider,
  ListItemIcon,
  Button,
} from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import LogoutIcon from "@mui/icons-material/Logout";
import { stringAvatar } from "../../utils/avatarUtils";
import { getFirstName } from "../../utils/nameUtils";
import { getImageUrl } from "../../utils/imageUtils";
import Logo from "./Logo";
import NotificationMenu from "./NotificationMenu";

// Main navigation component with user menu and role-based navigation items
const Navigation = ({ hideNavigation }) => {
  const { user, signOut } = useAuth();
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  // Menu handlers
  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  // Navigation handlers
  const handleProfile = () => {
    handleClose();
    navigate(`/profile/${user.id}`);
  };

  const handleSignOut = async () => {
    try {
      handleClose();
      await signOut();
      navigate("/sign-in");
    } catch (error) {
      console.error("Failed to sign out:", error);
    }
  };

  // Generate avatar based on profile picture or name initials
  const getAvatar = (user) => {
    if (user.profile_picture) {
      return (
        <Avatar
          src={getImageUrl(user.profile_picture)}
          sx={{ width: 32, height: 32 }}
        />
      );
    }
    return (
      <Avatar
        {...stringAvatar(user.full_name)}
        sx={{ width: 32, height: 32 }}
      />
    );
  };

  if (hideNavigation) {
    return null;
  }

  return (
    <AppBar position="fixed" color="primary" elevation={1}>
      <Container maxWidth="lg">
        <Toolbar disableGutters sx={{ justifyContent: "space-between" }}>
          {/* Logo and navigation links */}
          <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
            <Logo variant="default" />
            {user && (
              <>
                <Button
                  color="inherit"
                  component={Link}
                  to="/courses"
                  sx={{ textTransform: "none" }}
                >
                  Courses
                </Button>
                <Button
                  color="inherit"
                  component={Link}
                  to="/my-courses"
                  sx={{ textTransform: "none" }}
                >
                  My Courses
                </Button>
                <Button
                  color="inherit"
                  component={Link}
                  to="/chat"
                  sx={{ textTransform: "none" }}
                >
                  Messages
                </Button>
                {user.user_type === "teacher" && (
                  <Button
                    color="inherit"
                    component={Link}
                    to="/users"
                    sx={{ textTransform: "none" }}
                  >
                    Users
                  </Button>
                )}
              </>
            )}
          </Box>
          {/* User profile section with dropdown menu */}
          {user && (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: 1,
              }}
            >
              {/* Notification menu */}
              <NotificationMenu />
              {/* User greeting */}
              <Typography
                color="inherit"
                sx={{
                  opacity: 0.9,
                  display: { xs: "none", sm: "block" },
                }}
              >
                Hello, {getFirstName(user.full_name)}
              </Typography>
              {/* Avatar with dropdown trigger */}
              <Stack
                onClick={handleMenu}
                direction="row"
                spacing={0.5}
                alignItems="center"
                sx={{
                  padding: "4px 8px",
                  cursor: "pointer",
                  "&:hover": {
                    opacity: 0.9,
                  },
                }}
              >
                {getAvatar(user)}
                <KeyboardArrowDownIcon
                  fontSize="small"
                  sx={{
                    color: "white",
                    transition: "transform 0.2s",
                    transform: open ? "rotate(180deg)" : "rotate(0)",
                  }}
                />
              </Stack>
              {/* Dropdown menu with profile and logout options */}
              <Menu
                id="menu-appbar"
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "right",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                open={open}
                onClose={handleClose}
              >
                <MenuItem onClick={handleProfile}>
                  <ListItemIcon>
                    <PersonOutlineIcon fontSize="small" />
                  </ListItemIcon>
                  Profile
                </MenuItem>
                <Divider />
                <MenuItem onClick={handleSignOut}>
                  <ListItemIcon>
                    <LogoutIcon fontSize="small" />
                  </ListItemIcon>
                  Sign Out
                </MenuItem>
              </Menu>
            </Box>
          )}
        </Toolbar>
      </Container>
    </AppBar>
  );
};

export default Navigation;

