import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Container,
  Paper,
  Typography,
  TextField,
  Button,
  Box,
  Alert,
  FormControlLabel,
  Switch,
} from "@mui/material";
import { courseService } from "../../services/api";
import useFormErrors from "../../hooks/useFormErrors";
import { useNotification } from "../../contexts/NotificationContext";

const CreateCourse = () => {
  const navigate = useNavigate();
  const { showNotification } = useNotification();
  const [formData, setFormData] = useState({
    title: "",
    description: "",
    is_published: true,
  });
  const [loading, setLoading] = useState(false);
  const { errors, generalError, clearFieldError, handleErrors } =
    useFormErrors();

  const handleChange = (e) => {
    const { name, value, checked } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: name === "is_published" ? checked : value,
    }));
    clearFieldError(name);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const response = await courseService.createCourse(formData);
      const course = response.course;
      showNotification("Course successfully created!", "success");
      navigate(`/courses/${course.slug}/edit`);
    } catch (err) {
      handleErrors(err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container maxWidth="md" sx={{ py: 4 }}>
      <Paper elevation={2} sx={{ p: 4 }}>
        <Typography variant="h4" gutterBottom>
          Create New Course
        </Typography>

        {generalError && (
          <Alert severity="error" sx={{ mb: 3 }}>
            {generalError}
          </Alert>
        )}

        <Box component="form" onSubmit={handleSubmit} sx={{ mt: 2 }}>
          <TextField
            label="Course Title"
            name="title"
            value={formData.title}
            onChange={handleChange}
            fullWidth
            required
            error={!!errors.title}
            helperText={errors.title}
            sx={{ mb: 3 }}
          />

          <TextField
            label="Course Description"
            name="description"
            value={formData.description}
            onChange={handleChange}
            fullWidth
            required
            multiline
            rows={4}
            error={!!errors.description}
            helperText={errors.description}
            sx={{ mb: 3 }}
          />

          <FormControlLabel
            control={
              <Switch
                checked={formData.is_published}
                onChange={handleChange}
                name="is_published"
              />
            }
            label="Publish course immediately"
            sx={{ mb: 3 }}
          />

          <Box sx={{ display: "flex", gap: 2, justifyContent: "flex-end" }}>
            <Button
              type="submit"
              variant="contained"
              disabled={loading}
              sx={{ minWidth: 120 }}
            >
              Create Course
            </Button>
            <Button
              variant="outlined"
              onClick={() => navigate("/courses")}
              disabled={loading}
            >
              Cancel
            </Button>
          </Box>
        </Box>
      </Paper>
    </Container>
  );
};

export default CreateCourse;
