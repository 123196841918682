import React from "react";
import {
  ListItem,
  ListItemText,
  ListItemIcon,
  IconButton,
} from "@mui/material";
import { Draggable } from "@hello-pangea/dnd";
import DragHandleIcon from "@mui/icons-material/DragHandle";
import EditIcon from "@mui/icons-material/Edit";
import { Link, useNavigate } from "react-router-dom";
import VideoLibraryIcon from "@mui/icons-material/VideoLibrary";
import TextSnippetIcon from "@mui/icons-material/TextSnippet";

const DraggableListItemContent = React.forwardRef(
  ({ item, dragHandleProps, draggableProps, editable, editUrl }, ref) => {
    const navigate = useNavigate();

    return (
      <ListItem
        ref={ref}
        {...draggableProps}
        onClick={() => navigate(editUrl)}
        sx={{
          mb: 1,
          borderRadius: 1,
          border: "1px solid",
          borderColor: "divider",
          backgroundColor: "background.paper",
          cursor: "pointer",
          "&:hover": {
            backgroundColor: "action.hover",
          },
        }}
        secondaryAction={
          editable ? (
            <IconButton
              size="small"
              color="primary"
              component={Link}
              to={`${editUrl}`}
              onClick={(e) => e.stopPropagation()}
              sx={{
                "&:hover": {
                  backgroundColor: "transparent",
                  color: "primary.main",
                },
              }}
            >
              <EditIcon />
            </IconButton>
          ) : null
        }
      >
        {dragHandleProps && (
          <ListItemIcon
            {...dragHandleProps}
            sx={{
              cursor: "grab",
              color: "text.secondary",
              minWidth: 40,
              "&:hover": { color: "primary.main" },
            }}
          >
            <DragHandleIcon />
          </ListItemIcon>
        )}
        <ListItemIcon sx={{ minWidth: 40 }}>
          {item.material?.video_file ? (
            <VideoLibraryIcon color="text" />
          ) : (
            <TextSnippetIcon color="text" />
          )}
        </ListItemIcon>
        <ListItemText
          primary={item.title}
          secondary={item.description}
          sx={{
            "& .MuiListItemText-primary": {
              fontWeight: 500,
              color: "text.primary",
            },
            "& .MuiListItemText-secondary": {
              display: "-webkit-box",
              WebkitLineClamp: 2,
              WebkitBoxOrient: "vertical",
              overflow: "hidden",
            },
          }}
        />
      </ListItem>
    );
  }
);

const DraggableListItem = ({
  item,
  index,
  draggable,
  editable,
  editUrl,
  onDelete,
}) => {
  if (draggable) {
    return (
      <Draggable draggableId={item.id.toString()} index={index}>
        {(provided) => (
          <DraggableListItemContent
            item={item}
            draggableProps={provided.draggableProps}
            dragHandleProps={provided.dragHandleProps}
            editable={editable}
            editUrl={editUrl}
            onDelete={onDelete}
            ref={provided.innerRef}
          />
        )}
      </Draggable>
    );
  }
  return (
    <DraggableListItemContent
      item={item}
      dragHandleProps={null}
      draggableProps={{}}
      editable={editable}
      editUrl={editUrl}
      onDelete={onDelete}
    />
  );
};

export default DraggableListItem;
