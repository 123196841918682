import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL || "http://localhost:8000";

const api = axios.create({
  baseURL: API_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

// Add token to requests if it exists
api.interceptors.request.use((config) => {
  const accessToken = localStorage.getItem("accessToken");
  if (accessToken) {
    config.headers.Authorization = `Bearer ${accessToken}`;
  }
  return config;
});

// Handle token refresh
api.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;

    // If error is 401 and we haven't tried to refresh token yet
    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;

      try {
        const refreshToken = localStorage.getItem("refreshToken");
        if (!refreshToken) {
          throw new Error("No refresh token");
        }

        const response = await axios.post(`${API_URL}/api/token/refresh/`, {
          refresh: refreshToken,
        });

        const { access } = response.data;
        localStorage.setItem("accessToken", access);

        // Retry original request with new token
        originalRequest.headers.Authorization = `Bearer ${access}`;
        return api(originalRequest);
      } catch (refreshError) {
        // If refresh fails, clear tokens and redirect to login
        localStorage.removeItem("accessToken");
        localStorage.removeItem("refreshToken");
        window.location.href = "/sign-in";
        return Promise.reject(refreshError);
      }
    }

    return Promise.reject(error);
  }
);

export const authService = {
  signIn: async (credentials) => {
    const response = await api.post("/api/sign-in/", credentials);
    if (response.data.tokens) {
      localStorage.setItem("accessToken", response.data.tokens.access);
      localStorage.setItem("refreshToken", response.data.tokens.refresh);
    }
    return response.data;
  },

  signUp: async (userData) => {
    const response = await api.post("/api/sign-up/", userData);
    if (response.data.tokens) {
      localStorage.setItem("accessToken", response.data.tokens.access);
      localStorage.setItem("refreshToken", response.data.tokens.refresh);
    }
    return response.data;
  },

  signOut: async () => {
    const refreshToken = localStorage.getItem("refreshToken");
    try {
      await api.post("/api/sign-out/", { refresh: refreshToken });
    } finally {
      localStorage.removeItem("accessToken");
      localStorage.removeItem("refreshToken");
    }
  },

  isAuthenticated: () => {
    return !!localStorage.getItem("accessToken");
  },
};

export const profileService = {
  getProfile: async (userId) => {
    const response = await api.get(`/api/profile/${userId}/`);
    return response.data;
  },

  updateProfile: async (userId, formData) => {
    const response = await api.put(`/api/profile/${userId}/`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return response.data;
  },
};

export const courseService = {
  getCourses: async (params = {}) => {
    const response = await api.get("/api/courses/", { params });
    return response.data;
  },

  enrollInCourse: async (courseSlug) => {
    const response = await api.post(`/api/courses/${courseSlug}/enroll/`);
    return response.data;
  },

  unenrollFromCourse: async (courseSlug) => {
    const response = await api.post(`/api/courses/${courseSlug}/unenroll/`);
    return response.data;
  },

  updateCourse: async (courseSlug, courseData) => {
    const response = await api.put(`/api/courses/${courseSlug}/`, courseData);
    return response.data;
  },

  deleteCourse: async (courseSlug) => {
    const response = await api.delete(`/api/courses/${courseSlug}/`);
    return response.data;
  },

  createCourse: async (courseData) => {
    const response = await api.post("/api/courses/", courseData);
    return response.data;
  },

  getCourse: async (courseSlug) => {
    const response = await api.get(`/api/courses/${courseSlug}/`);
    return response.data;
  },

  getFeedback: async (courseSlug, params = {}) => {
    const response = await api.get(`/api/courses/${courseSlug}/feedback/`, {
      params,
    });
    return response.data;
  },

  submitFeedback: async (courseSlug, feedbackData) => {
    const response = await api.post(
      `/api/courses/${courseSlug}/feedback/`,
      feedbackData
    );
    return response.data;
  },

  updateFeedback: async (courseSlug, feedbackId, feedbackData) => {
    const response = await api.put(
      `/api/courses/${courseSlug}/feedback/${feedbackId}/`,
      feedbackData
    );
    return response.data;
  },

  deleteFeedback: async (courseSlug, feedbackId) => {
    const response = await api.delete(
      `/api/courses/${courseSlug}/feedback/${feedbackId}/`
    );
    return response.data;
  },

  getEnrolledUsers: async (courseSlug) => {
    const response = await api.get(`/api/courses/${courseSlug}/students/`);
    return response.data;
  },

  removeStudent: async (courseSlug, studentId) => {
    const response = await api.post(
      `/api/courses/${courseSlug}/remove_student/`,
      {
        student_id: studentId,
      }
    );
    return response.data;
  },
};

export const topicService = {
  createTopic: async (courseSlug, topicData, isMultipart = false) => {
    const config = isMultipart
      ? { headers: { "Content-Type": "multipart/form-data" } }
      : {};
    const response = await api.post(
      `/api/courses/${courseSlug}/topics/`,
      topicData,
      config
    );
    return response.data;
  },

  updateTopic: async (courseSlug, topicId, topicData, isMultipart = false) => {
    const config = isMultipart
      ? { headers: { "Content-Type": "multipart/form-data" } }
      : {};
    const response = await api.put(
      `/api/courses/${courseSlug}/topics/${topicId}/`,
      topicData,
      config
    );
    return response.data;
  },

  deleteTopic: async (courseSlug, topicId) => {
    const response = await api.delete(
      `/api/courses/${courseSlug}/topics/${topicId}/`
    );
    return response.data;
  },

  getTopic: async (courseSlug, topicId) => {
    const response = await api.get(
      `/api/courses/${courseSlug}/topics/${topicId}/`
    );
    return response.data;
  },

  updateTopicOrder: async (courseSlug, orderData) => {
    const response = await api.patch(
      `/api/courses/${courseSlug}/topics/${orderData.topic_id}/reorder/`,
      {
        new_order: orderData.new_order,
      }
    );
    return response.data;
  },
};

export const statusUpdateService = {
  getStatusUpdates: async (params) => {
    const response = await api.get("/api/status-updates/", {
      params: {
        ...params,
        page_size: params.page_size || 10,
      },
    });
    return {
      results: response.data.results,
      next: response.data.next,
      previous: response.data.previous,
      count: response.data.count,
    };
  },
  createStatusUpdate: async (data) => {
    const response = await api.post("/api/status-updates/", data);
    return response.data;
  },
  updateStatusUpdate: async (id, data) => {
    const response = await api.put(`/api/status-updates/${id}/`, data);
    return response.data;
  },
  deleteStatusUpdate: async (id) => {
    const response = await api.delete(`/api/status-updates/${id}/`);
    return response.data;
  },
};

export const userService = {
  getUsers: async (params = {}) => {
    try {
      const response = await api.get("/api/users/", {
        params: {
          page_size: params.page_size || 10,
          ...params,
        },
      });

      return {
        users: response.data.results || [],
        next: response.data.next,
        previous: response.data.previous,
        count: response.data.count,
      };
    } catch (error) {
      console.error("Error fetching users:", error);
      return { users: [] };
    }
  },
};

export const chatService = {
  getChatRooms: async () => {
    const response = await api.get("/api/chat/");
    return response.data;
  },

  getChatMessages: async (roomId) => {
    const response = await api.get(`/api/chat/${roomId}/messages/`);
    return response.data;
  },

  sendMessage: async (roomId, data) => {
    const response = await api.post(`/api/chat/${roomId}/send_message/`, data);
    return response.data;
  },

  createChatRoom: async (data) => {
    const response = await api.post("/api/chat/", data);
    return response.data;
  },

  getChatRoom: async (roomId) => {
    const response = await api.get(`/api/chat/${roomId}/`);
    return response.data;
  },
};

export const notificationService = {
  getNotifications: async () => {
    const response = await api.get("/api/notifications/");
    return response.data;
  },
  markAsRead: async (id) => {
    const response = await api.post(`/api/notifications/${id}/mark_as_read/`);
    return response.data;
  },
  markAllRead: async () => {
    const response = await api.post("/api/notifications/mark_all_as_read/");
    return response.data;
  },
};

export default api;
