import React, { useState, useEffect } from "react";
import { Typography, Box } from "@mui/material";
import { useAuth } from "../../contexts/AuthContext";
import AddIcon from "@mui/icons-material/Add";
import SchoolIcon from "@mui/icons-material/School";
import { courseService } from "../../services/api";
import CourseListItem from "../courses/CourseListItem";
import LoadingSpinner from "../common/LoadingSpinner";
import EmptyState from "../common/EmptyState";

const ProfileCourseList = ({ userId, userType }) => {
  const { user } = useAuth();
  const isCurrentUser = user?.id === userId;
  const [courses, setCourses] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const loadCourses = async () => {
      try {
        const params =
          userType === "teacher"
            ? { created_by: userId }
            : { enrolled_student: userId };

        const response = await courseService.getCourses(params);
        setCourses(response);
      } catch (error) {
        console.error("Error loading courses:", error);
      } finally {
        setLoading(false);
      }
    };

    loadCourses();
  }, [userId, userType]);

  const getEmptyStateProps = () => {
    if (isCurrentUser) {
      if (userType === "teacher") {
        return {
          title: "No Courses Published Yet",
          description:
            "Start sharing your knowledge by creating your first course!",
          actionText: "Create Course",
          actionLink: "/courses/create",
          actionIcon: AddIcon,
        };
      }
      return {
        title: "No Current Courses",
        description: "Start your learning journey by enrolling in a course!",
        actionText: "Browse Courses",
        actionLink: "/courses",
        actionIcon: SchoolIcon,
      };
    }
    // Default props for other users' profiles
    return {
      title:
        userType === "teacher" ? "No Published Courses" : "No Current Courses",
      description:
        userType === "teacher"
          ? "This teacher has not published any courses yet."
          : "This student has not enrolled in any courses yet.",
    };
  };

  if (loading) return <LoadingSpinner />;

  return (
    <Box sx={{ mt: 3 }}>
      <Typography variant="h6" gutterBottom>
        {userType === "teacher"
          ? isCurrentUser
            ? "Courses You Created"
            : "Created Courses"
          : isCurrentUser
          ? "Your Current Courses"
          : "Current Courses"}
      </Typography>

      <Box sx={{ mt: 2 }}>
        {courses.length > 0 ? (
          courses.map((course) => (
            <Box key={course.id} sx={{ mb: 2 }}>
              <CourseListItem
                title={course.title}
                description={course.description}
                slug={course.slug}
                teacherId={course.teacher}
                teacherName={course.teacher_name}
                studentCount={course.student_count}
                isEnrolled={course.is_enrolled}
                avgRating={course.avg_rating}
              />
            </Box>
          ))
        ) : (
          <EmptyState {...getEmptyStateProps()} />
        )}
      </Box>
    </Box>
  );
};

export default ProfileCourseList;
