import React, { useState, useEffect } from "react";
import {
  List,
  Typography,
  Paper,
  Box,
  Container,
  Divider,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { chatService } from "../../services/api";
import LoadingScreen from "../common/LoadingScreen";
import ChatListItem from "./ChatListItem";
import { useAuth } from "../../contexts/AuthContext";
import EmptyState from "../common/EmptyState";

// Displays a list of all chat conversations for the current user.
const ChatList = () => {
  // State for managing chat rooms data and loading state
  const [chatRooms, setChatRooms] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const { user } = useAuth();

  useEffect(() => {
    // Fetch chat rooms data from the API
    const loadChatRooms = async () => {
      try {
        const response = await chatService.getChatRooms();
        setChatRooms(response);
      } catch (err) {
        console.error("Error loading chat rooms:", err);
      } finally {
        setLoading(false);
      }
    };

    loadChatRooms();
  }, []);

  if (loading) return <LoadingScreen />;

  // Check if there are no chat rooms
  const hasNoChats = chatRooms.length === 0;

  return (
    <Paper elevation={2} sx={{ height: "100%" }}>
      {/* Header section with title */}
      <Box sx={{ p: 2, borderBottom: 1, borderColor: "divider" }}>
        <Container maxWidth="lg">
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography variant="h6">Messages</Typography>
          </Box>
        </Container>
      </Box>

      {/* Chat rooms list container */}
      <Container maxWidth="lg" sx={{ minHeight: "calc(100vh - 130px)" }}>
        {hasNoChats ? (
          /* Empty state when there are no chat rooms */
          <EmptyState
            title="No conversations yet"
            description="Start a conversation with another user to see messages here."
          />
        ) : (
          /* List of chat rooms */
          <List>
            {chatRooms.map((room, index) => (
              <>
                <ChatListItem
                  key={room.id}
                  room={room}
                  userId={user?.id}
                  onClick={() => navigate(`/chat/${room.id}`)}
                />
                {/* Divider between chat rooms except for the last one */}
                {index < chatRooms.length - 1 && (
                  <Divider variant="inset" component="li" />
                )}
              </>
            ))}
          </List>
        )}
      </Container>
    </Paper>
  );
};

export default ChatList;

