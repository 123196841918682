import React, { useState, useEffect, useCallback } from "react";
import { useNavigate, useParams } from "react-router-dom";
import TopicForm from "./TopicForm";
import { topicService } from "../../services/api";
import useFormErrors from "../../hooks/useFormErrors";
import LoadingScreen from "../common/LoadingScreen";
import axios from "axios";
import { useNotification } from "../../contexts/NotificationContext";
import { useAuth } from "../../contexts/AuthContext";

const EditTopic = () => {
  const navigate = useNavigate();
  const { courseSlug, topicId } = useParams();
  const { showNotification } = useNotification();
  const [formData, setFormData] = useState({
    title: "",
    description: "",
    material: { content: "", video_file: null, current_video: null },
  });
  const [attachments, setAttachments] = useState([]);
  const [initialAttachmentsCount, setInitialAttachmentsCount] = useState(0);
  const [loading, setLoading] = useState(true);
  const { user } = useAuth();
  const { errors, generalError, handleErrors, clearFieldError } =
    useFormErrors();
  const editCourseUrl = `/courses/${courseSlug}/edit`;

  const loadTopic = useCallback(async () => {
    try {
      const response = await topicService.getTopic(courseSlug, topicId);

      if (user.id !== response.teacher_id) navigate(`/courses/${courseSlug}`);

      setFormData({
        title: response.title,
        description: response.description,
        material: {
          content: response.material?.content || "",
          video_file: null,
          current_video: response.material?.video_file || null,
        },
      });
      if (response.material && response.material.attachments) {
        // Fetch file sizes for each attachment
        const attachmentsWithSize = await Promise.all(
          response.material.attachments.map(async (att) => {
            let fileSize = 0;
            try {
              const headResponse = await axios.head(att.file);
              fileSize = parseInt(headResponse.headers["content-length"] || 0);
            } catch (error) {
              console.warn(`Could not fetch size for ${att.file}`, error);
              fileSize = 1024 * 1024;
            }
            return {
              id: att.id,
              title: att.title,
              type: att.file_type,
              file: {
                name: att.file,
                size: fileSize,
                type:
                  att.file_type === "image" ? "image/jpeg" : "application/pdf",
              },
              isExisting: true,
            };
          })
        );

        setAttachments(attachmentsWithSize);
        setInitialAttachmentsCount(attachmentsWithSize.length);
      }
    } catch (err) {
      handleErrors(err);
    } finally {
      setLoading(false);
    }
  }, [courseSlug, topicId, handleErrors, user.id, navigate]);

  useEffect(() => {
    loadTopic();
  }, [loadTopic]);

  const handleChange = (e) => {
    const { name, value, files } = e.target;
    if (name === "material.video_file") {
      setFormData((prev) => ({
        ...prev,
        material: { ...prev.material, video_file: files[0] },
      }));
    } else if (name.startsWith("material.")) {
      const key = name.split(".")[1];
      setFormData((prev) => ({
        ...prev,
        material: { ...prev.material, [key]: value },
      }));
    } else {
      setFormData((prev) => ({ ...prev, [name]: value }));
    }
    clearFieldError(name);
  };

  const handleRemoveVideo = () => {
    setFormData((prev) => ({
      ...prev,
      material: {
        ...prev.material,
        video_file: null,
        current_video: null,
      },
    }));
  };

  const handleRemoveAttachment = (index) => {
    setAttachments((prev) => prev.filter((_, i) => i !== index));
  };

  const handleAttachmentTitleChange = (index, newTitle) => {
    setAttachments((prev) =>
      prev.map((att, i) => {
        if (i === index) {
          return {
            ...att,
            title: newTitle,
            isModified: att.isExisting ? true : false,
          };
        }
        return att;
      })
    );
  };

  // Add onFileSelect handler
  const onFileSelect = (e) => {
    const files = Array.from(e.target.files);
    const newAtts = files.map((file) => ({
      file,
      title: file.name,
      type: file.type.startsWith("image/") ? "image" : "pdf",
      // Flag as new attachment
      isExisting: false,
    }));
    setAttachments((prev) => [...prev, ...newAtts]);
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const data = new FormData();
      data.append("title", formData.title);
      data.append("description", formData.description);
      data.append("material.content", formData.material.content);

      // Handle video file
      if (formData.material.video_file) {
        data.append("material.video_file", formData.material.video_file);
      }

      // Add clear_video flag if video was removed
      if (!formData.material.video_file && !formData.material.current_video) {
        data.append("clear_video", "true");
      }

      // Handle attachments
      const existingAttachments = attachments.filter((att) => att.isExisting);
      const newAttachments = attachments.filter((att) => !att.isExisting);
      const modifiedAttachments = existingAttachments.filter(
        (att) => att.isModified
      );

      // Send IDs and new titles of existing attachments that need updating
      if (modifiedAttachments.length > 0) {
        modifiedAttachments.forEach((att) => {
          data.append("update_attachment_ids", att.id);
          data.append("update_attachment_titles", att.title);
        });
      }

      // Send IDs of unmodified existing attachments to keep
      const unmodifiedAttachments = existingAttachments.filter(
        (att) => !att.isModified
      );
      if (unmodifiedAttachments.length > 0) {
        unmodifiedAttachments.forEach((att) => {
          data.append("keep_attachments", att.id);
        });
      }

      // Send new attachments
      if (newAttachments.length > 0) {
        newAttachments.forEach((att) => {
          data.append("uploaded_files", att.file);
          data.append("attachment_titles", att.title);
        });
      }

      // If all attachments were removed, send clear flag
      if (attachments.length === 0 && initialAttachmentsCount > 0) {
        data.append("clear_attachments", "true");
      }

      await topicService.updateTopic(courseSlug, topicId, data, true);
      showNotification("Topic successfully updated!", "success");
      navigate(editCourseUrl);
    } catch (err) {
      handleErrors(err);
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteTopic = async () => {
    try {
      await topicService.deleteTopic(courseSlug, topicId);
      showNotification(`Topic "${formData.title}" has been deleted`, "success");
      navigate(editCourseUrl);
    } catch (err) {
      showNotification("Failed to delete topic", "error");
      handleErrors(err);
    }
  };

  if (loading) {
    return <LoadingScreen />;
  }

  return (
    <TopicForm
      formTitle="Edit Topic"
      formData={formData}
      attachments={attachments}
      errors={errors}
      generalError={generalError}
      loading={loading}
      onChange={handleChange}
      onFileSelect={onFileSelect}
      handleRemoveAttachment={handleRemoveAttachment}
      handleAttachmentTitleChange={handleAttachmentTitleChange}
      handleRemoveVideo={handleRemoveVideo}
      onSubmit={onSubmit}
      onDelete={handleDeleteTopic}
      actionLabel="Save Changes"
      cancelAction={() => navigate(editCourseUrl)}
    />
  );
};

export default EditTopic;
