import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  useCallback,
} from "react";
import { useAuth } from "./AuthContext";
import { websocketConfig } from "../utils/websocketUtils";

const PresenceContext = createContext({});

export const PresenceProvider = ({ children }) => {
  const [onlineUsers, setOnlineUsers] = useState(new Set());
  const [socket, setSocket] = useState(null);
  const { user } = useAuth();

  // Handle socket connection
  const connectWebSocket = useCallback(() => {
    if (!user) return;

    try {
      const token = localStorage.getItem("accessToken");
      const ws = new WebSocket(
        websocketConfig.getWebSocketUrl(`/ws/presence/?token=${token}`)
      );

      ws.onopen = () => {
        console.log("Presence WebSocket connected successfully");
        setSocket(ws);
      };

      ws.onmessage = (event) => {
        try {
          const data = JSON.parse(event.data);
          if (data.type === "presence_update") {
            setOnlineUsers(new Set(data.online_users));
          }
        } catch (err) {
          console.error("Error processing message:", err);
        }
      };

      ws.onerror = (error) => {
        console.error("Presence WebSocket error:", error);
        if (ws.readyState === WebSocket.OPEN) {
          ws.close();
        }
      };

      ws.onclose = (event) => {
        console.log("Presence WebSocket closed, retrying in 5s...", event.code);
        setSocket(null);
        setTimeout(connectWebSocket, 5000);
      };

      return () => {
        if (ws && ws.readyState === WebSocket.OPEN) {
          ws.close();
        }
      };
    } catch (err) {
      console.error("Error creating WebSocket:", err);
      setTimeout(connectWebSocket, 5000);
    }
  }, [user]);

  // Initialize WebSocket connection
  useEffect(() => {
    const cleanup = connectWebSocket();
    return () => {
      if (cleanup) cleanup();
    };
  }, [connectWebSocket]);

  // Check if a user is online
  const isUserOnline = useCallback(
    (userId) => {
      return onlineUsers.has(userId);
    },
    [onlineUsers]
  );

  return (
    <PresenceContext.Provider
      value={{
        isUserOnline,
        onlineUsers: Array.from(onlineUsers),
        isConnected: socket?.readyState === WebSocket.OPEN,
      }}
    >
      {children}
    </PresenceContext.Provider>
  );
};

export const usePresence = () => {
  const context = useContext(PresenceContext);
  if (!context) {
    throw new Error("usePresence must be used within a PresenceProvider");
  }
  return context;
};

export default PresenceProvider;
