import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
} from "@mui/material";

// Confirmation dialog with title, message, and confirm/cancel buttons
const ConfirmDialog = ({
  open,
  title,
  message,
  onConfirm,
  onClose,
  confirmText = "Confirm",
  cancelText = "Cancel",
}) => (
  <Dialog open={open} onClose={onClose}>
    {/* Dialog title */}
    <DialogTitle>{title}</DialogTitle>
    {/* Confirmation message */}
    <DialogContent>
      <Typography>{message}</Typography>
    </DialogContent>
    {/* Action buttons */}
    <DialogActions>
      <Button onClick={onClose}>{cancelText}</Button>
      <Button onClick={onConfirm} color="error" variant="contained" autoFocus>
        {confirmText}
      </Button>
    </DialogActions>
  </Dialog>
);

export default ConfirmDialog;
