import React from "react";
import { Box, Typography, Rating as MuiRating } from "@mui/material";

// Rating component with optional numeric display
const Rating = ({ rating, showNumber = true, size = "small" }) => {
  return (
    // Wrapper for rating stars and numeric value
    <Box sx={{ display: "flex", alignItems: "center", gap: 0.5 }}>
      {/* Read-only rating stars */}
      <MuiRating value={rating || 0} precision={0.5} readOnly size={size} />
      {/* Optional numeric rating display */}
      {showNumber && (
        <Typography variant="body2" color="text.secondary">
          {rating ? `(${rating.toFixed(1)})` : "(No ratings)"}
        </Typography>
      )}
    </Box>
  );
};

export default Rating;
