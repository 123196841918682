import React from "react";
import { Box, Typography } from "@mui/material";
import { format } from "date-fns";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import DoneIcon from "@mui/icons-material/Done";

export default function MessageBubble({
  message,
  isSender,
  isFirstInGroup,
  isLastInGroup,
}) {
  // Calculate bubble shape based on position in group
  const borderRadius = isSender ? `18px 18px 0px 18px` : `18px 18px 18px 0px`;

  return (
    <Box
      key={message.id}
      sx={{
        display: "flex",
        justifyContent: isSender ? "flex-end" : "flex-start",
        alignItems: "flex-end",
        mb: isLastInGroup ? 2 : 0.5,
        mt: isFirstInGroup ? 2 : 0.5,
        opacity: message.pending ? 0.7 : 1,
      }}
    >
      {/* Message bubble */}
      <Box
        sx={{
          maxWidth: { xs: "85%", sm: "70%" },
          bgcolor: isSender ? "primary.main" : "grey.100",
          color: isSender ? "white" : "text.primary",
          p: 1.5,
          px: 2,
          borderRadius,
          position: "relative",
          boxShadow: 1,
          "&::after": {
            content: '""',
            position: "absolute",
            bottom: 0,
            [isSender ? "right" : "left"]: -10,
            width: 0,
            height: 0,
            borderTop: "10px solid transparent",
            borderBottom: "0px solid transparent",
            borderLeft: isSender ? `10px solid ${"primary.main"}` : "none",
            borderRight: "none",
          },
        }}
      >
        {/* Sender name */}
        {!isSender && isFirstInGroup && message.sender_name && (
          <Typography
            variant="caption"
            sx={{
              color: "primary.main",
              fontWeight: "bold",
              display: "block",
              mb: 0.5,
            }}
          >
            {message.sender_name}
          </Typography>
        )}

        {/* Message content */}
        <Typography variant="body1" sx={{ wordBreak: "break-word" }}>
          {message.content}
        </Typography>

        {/* Time and status */}
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: 0.5,
            opacity: 0.7,
            justifyContent: "flex-end",
            mt: 0.5,
          }}
        >
          <Typography variant="caption" sx={{ fontSize: "0.7rem" }}>
            {format(new Date(message.created_at), "HH:mm")}
          </Typography>
          {isSender && (
            <Box sx={{ display: "flex", alignItems: "center", ml: 0.5 }}>
              {message.pending ? (
                <DoneIcon
                  sx={{
                    fontSize: "0.9rem",
                    color: isSender
                      ? "rgba(255,255,255,0.7)"
                      : "text.secondary",
                  }}
                />
              ) : (
                <DoneAllIcon
                  sx={{ fontSize: "0.9rem", color: "primary.light" }}
                />
              )}
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
}
