import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
} from "@mui/material";

// Confirmation dialog for removing a student from a course
const RemoveStudentDialog = ({ open, onClose, onConfirm, studentName }) => {
  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      {/* Dialog header */}
      <DialogTitle>Remove Student</DialogTitle>
      {/* Confirmation message */}
      <DialogContent>
        <Typography>
          Are you sure you want to remove {studentName} from this course? This
          action cannot be undone.
        </Typography>
      </DialogContent>
      {/* Action buttons */}
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
        <Button onClick={onConfirm} color="error" variant="contained">
          Remove
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default RemoveStudentDialog;
