import React from "react";
import {
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
  Box,
} from "@mui/material";
import { format } from "date-fns";
import UserTypeChip from "../common/UserTypeChip";
import getOtherParticipant from "./getOtherParticipant";
import { usePresence } from "../../contexts/PresenceContext";
import UserAvatar from "../common/UserAvatar";

// Renders an individual chat room item in the chat list.
const ChatListItem = ({ room, userId, onClick }) => {
  const { isUserOnline } = usePresence();
  // Find the other participant by excluding current user
  const otherParticipant = getOtherParticipant(room?.participants_info, userId);

  return (
    <ListItem
      onClick={onClick}
      sx={{
        cursor: "pointer",
        "&:hover": { bgcolor: "action.hover" },
      }}
    >
      {/* Participant's avatar */}
      <ListItemAvatar>
        <UserAvatar
          src={otherParticipant.profile_picture}
          isOnline={isUserOnline(otherParticipant.id)}
        />
      </ListItemAvatar>
      {/* Participant name and user type */}
      <ListItemText
        primary={
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Typography component="span">
              {otherParticipant.full_name}
            </Typography>
            {isUserOnline(otherParticipant.id) && (
              <Typography
                component="span"
                variant="caption"
                sx={{ ml: 1, color: "success.main" }}
              >
                (Online)
              </Typography>
            )}
            <UserTypeChip
              userType={otherParticipant.user_type}
              sx={{ ml: 1 }}
            />
          </Box>
        }
        // Last message preview with timestamp
        secondary={
          room.last_message ? (
            <>
              <Typography component="span" variant="body2" color="text.primary">
                {room.last_message.content.substring(0, 30)}
                {room.last_message.content.length > 30 ? "..." : ""}
              </Typography>
              <Typography component="span" variant="caption" sx={{ ml: 1 }}>
                {format(new Date(room.last_message.created_at), "MMM d, HH:mm")}
              </Typography>
            </>
          ) : (
            "No messages yet"
          )
        }
      />
    </ListItem>
  );
};

export default ChatListItem;

